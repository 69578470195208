import AuthDTO from '../../../dtos/auth/AuthDTO';
import { ContributorDTO } from '../../../dtos/auth/ContributorsDTO';

export enum AuthTypes {
  LOGIN_REQUEST = '@auth/LOGIN_REQUEST',
  LOGIN_SUCCCES = '@auth/LOGIN_SUCCCES',
  LOGIN_ERROR = '@auth/LOGIN_ERROR',
  LOGOUT = '@auth/LOGOUT',
  SET_TOKEN = '@auth/SET_TOKEN',
  PERSIST_REHYDRATATE = 'persist/REHYDRATE',
}

export interface AuthState {
  readonly data?: AuthDTO | null;
  readonly token: string | null;
  readonly loading: boolean;
  readonly error: boolean;
}
