export enum ComboTypes {
  SELECTED_COMBO_ID = '@combos/SELECTED_COMBO_ID',
  SET_VIEW_TYPE_COMBO = '@combos/SET_VIEW_TYPE_COMBO',
  CHANGE_COMBO_PAGE = '@combos/CHANGE_COMBO_PAGE',
  CHANGE_COMBO_LIMIT_PER_PAGE = '@combos/CHANGE_COMBO_LIMIT_PER_PAGE',
  CHANGE_COUNT_COMBO = '@combos/CHANGE_COUNT_COMBO',
}

export interface CombosState {
  readonly selectedComboId?: string | null;
  readonly viewTypeCombo: string; // view | edit
  readonly comboPage: number;
  readonly comboLimitPerPage: number;
  readonly countCombo: number;
}
