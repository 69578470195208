import api from '../../services/api';

import AuthDTO from '../../dtos/auth/AuthDTO';
import LoginDTO from '../../dtos/auth/LoginDTO';

const AuthAPI = {
  login: async ({ login, password }: LoginDTO): Promise<AuthDTO> => {
    const response = await api.post<AuthDTO>('/auth/login', {
      user: login,
      password,
    });

    return response.data;
  },

  resetPassword: async (email: string): Promise<number> => {
    const { status } = await api.post('/auth/send-password-reset-email', {
      email,
    });

    return status;
  },
};

export default AuthAPI;
