import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';

import * as ComboActions from './actions';
import { ComboTypes, CombosState } from './types';

const INITIAL_STATE: CombosState = {
  selectedComboId: null,
  viewTypeCombo: 'view',
  comboPage: 1,
  comboLimitPerPage: 10,
  countCombo: 0,
};

type ComboReducers<Action extends AnyAction> = Reducer<CombosState, Action>;

const setSelectedComboId: ComboReducers<
  ReturnType<typeof ComboActions.setSelectedComboId>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedComboId: payload.comboId,
});

const setViewTypeCombo: ComboReducers<
  ReturnType<typeof ComboActions.setViewTypeCombo>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  viewTypeCombo: payload.viewTypeCombo,
});

const changeComboPage: ComboReducers<
  ReturnType<typeof ComboActions.changeComboPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  comboPage: payload.comboPage,
});

const changeComboLimitPerPage: ComboReducers<
  ReturnType<typeof ComboActions.changeComboLimitPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  comboLimitPerPage: payload.comboLimitPerPage,
});

const changeCountCombo: ComboReducers<
  ReturnType<typeof ComboActions.changeCountCombo>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  countCombo: payload.countCombo,
});

const reducer = createReducer<CombosState>(INITIAL_STATE)
  .handleAction(ComboTypes.SELECTED_COMBO_ID, setSelectedComboId)
  .handleAction(ComboTypes.SET_VIEW_TYPE_COMBO, setViewTypeCombo)
  .handleAction(ComboTypes.CHANGE_COMBO_PAGE, changeComboPage)
  .handleAction(ComboTypes.CHANGE_COMBO_LIMIT_PER_PAGE, changeComboLimitPerPage)
  .handleAction(ComboTypes.CHANGE_COUNT_COMBO, changeCountCombo);

export default reducer;
