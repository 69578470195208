import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';

import * as CategoryActions from './actions';
import { CategoriesTypes, CategoryState } from './types';

const INITIAL_STATE: CategoryState = {
  selectedCategory: null,
  subCategory: null,
  categoryPage: 1,
  categoryLimitPerPage: 10,
  countCategory: 0,
  productByCategoryPage: 1,
  productByCategoryLimitPerPage: 10,
  countProductByCategory: 0,
};

type CategoryReducers<Action extends AnyAction> = Reducer<
  CategoryState,
  Action
>;

const setSelectedCategory: CategoryReducers<
  ReturnType<typeof CategoryActions.setSelectedCategory>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedCategory: payload.dataCategory,
});

const changeSubCategory: CategoryReducers<
  ReturnType<typeof CategoryActions.changeSubCategory>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  subCategory: payload.subCategory,
});

const changeCategoryPage: CategoryReducers<
  ReturnType<typeof CategoryActions.changeCategoryPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  categoryPage: payload.categoryPage,
});

const changeCategoryLimitPerPage: CategoryReducers<
  ReturnType<typeof CategoryActions.changeCategoryLimitPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  categoryLimitPerPage: payload.categoryLimitPerPage,
});

const changeCountCategory: CategoryReducers<
  ReturnType<typeof CategoryActions.changeCountCategory>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  countCategory: payload.countCategory,
});

const changeProductCategoryPage: CategoryReducers<
  ReturnType<typeof CategoryActions.changeProductCategoryPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  productByCategoryPage: payload.productByCategoryPage,
});

const changeProductCategoryLimitPerPage: CategoryReducers<
  ReturnType<typeof CategoryActions.changeProductCategoryLimitPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  productByCategoryLimitPerPage: payload.productByCategoryLimitPerPage,
});

const changeCountProductCategory: CategoryReducers<
  ReturnType<typeof CategoryActions.changeCountProductCategory>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  countProductByCategory: payload.countProductByCategory,
});

const reducer = createReducer<CategoryState>(INITIAL_STATE)
  .handleAction(CategoriesTypes.SELECTED_CATEGORY, setSelectedCategory)
  .handleAction(CategoriesTypes.CHANGE_SUB_CATEGORY, changeSubCategory)
  .handleAction(CategoriesTypes.CHANGE_CATEGORY_PAGE, changeCategoryPage)
  .handleAction(
    CategoriesTypes.CHANGE_CATEGORY_LIMIT_PER_PAGE,
    changeCategoryLimitPerPage,
  )
  .handleAction(CategoriesTypes.CHANGE_COUNT_CATEGORY, changeCountCategory)
  .handleAction(
    CategoriesTypes.CHANGE_PRODUCT_CATEGORY_PAGE,
    changeProductCategoryPage,
  )
  .handleAction(
    CategoriesTypes.CHANGE_PRODUCT_CATEGORY_LIMIT_PER_PAGE,
    changeProductCategoryLimitPerPage,
  )
  .handleAction(
    CategoriesTypes.CHANGE_COUNT_PRODUCT_CATEGORY,
    changeCountProductCategory,
  );

export default reducer;
