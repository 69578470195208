import React, { Suspense } from 'react';
import Modal from 'react-modal';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import * as dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

import store, { persistor } from './store';

import theme from './styles/theme';
import GlobalStyle from './styles/global';

import Routes from './routes';
import Loading from './components/Loading';

Modal.setAppElement('#root');

dayjs.locale('pt-br');

const App: React.FC = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <Routes />
          </Suspense>
          <GlobalStyle />
          <ToastContainer
            position="top-right"
            autoClose={2500}
            pauseOnHover
            icon
            hideProgressBar
            newestOnTop
            closeOnClick={false}
            // limit={1}
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            style={{
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '70%',
              zIndex: 9999,
            }}
          />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ThemeProvider>
);

export default App;
