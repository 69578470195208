import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';

import * as RoleActions from './actions';
import { RoleState, RoleTypes } from './types';

const INITIAL_STATE: RoleState = {
  selectedRole: null,
  viewTypeRole: null,
  rolePage: 1,
  roleLimitPerPage: 10,
  countRole: 0,
  usersRolePage: 1,
  usersRoleLimitPerPage: 10,
  countUsersRole: 0,
};

type RoleReducers<Action extends AnyAction> = Reducer<RoleState, Action>;

const setSelectedRole: RoleReducers<
  ReturnType<typeof RoleActions.setSelectedRole>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedRole: payload.selectedRole,
});

const setViewTypeRole: RoleReducers<
  ReturnType<typeof RoleActions.setViewTypeRole>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  viewTypeRole: payload.viewTypeRole,
});

const changeRolePage: RoleReducers<
  ReturnType<typeof RoleActions.changeRolePage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  rolePage: payload.rolePage,
});

const changeRoleLimitPerPage: RoleReducers<
  ReturnType<typeof RoleActions.changeRoleLimitPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  roleLimitPerPage: payload.roleLimitPerPage,
});

const changeCountRole: RoleReducers<
  ReturnType<typeof RoleActions.changeCountRole>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  countRole: payload.countRole,
});

const changeUsersRolePage: RoleReducers<
  ReturnType<typeof RoleActions.changeUsersRolePage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  usersRolePage: payload.usersRolePage,
});

const changeUsersRoleLimitPerPage: RoleReducers<
  ReturnType<typeof RoleActions.changeUsersRoleLimitPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  usersRoleLimitPerPage: payload.usersRoleLimitPerPage,
});

const changeCountUsersRole: RoleReducers<
  ReturnType<typeof RoleActions.changeCountUsersRole>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  countUsersRole: payload.countUsersRole,
});

const reducer = createReducer<RoleState>(INITIAL_STATE)
  .handleAction(RoleTypes.SELECTED_ROLE, setSelectedRole)
  .handleAction(RoleTypes.VIEW_TYPE_ROLE, setViewTypeRole)
  .handleAction(RoleTypes.CHANGE_ROLE_PAGE, changeRolePage)
  .handleAction(RoleTypes.CHANGE_ROLE_LIMIT_PER_PAGE, changeRoleLimitPerPage)
  .handleAction(RoleTypes.CHANGE_COUNT_ROLE, changeCountRole)
  .handleAction(RoleTypes.CHANGE_USERS_ROLE_PAGE, changeUsersRolePage)
  .handleAction(
    RoleTypes.CHANGE_USERS_ROLE_LIMIT_PER_PAGE,
    changeUsersRoleLimitPerPage,
  )
  .handleAction(RoleTypes.CHANGE_COUNT_USERS_ROLE, changeCountUsersRole);

export default reducer;
