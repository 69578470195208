import { PropsCategory, SubCategories } from '../../../dtos/auth/CategoriesDTO';

export enum CategoriesTypes {
  SELECTED_CATEGORY = '@categories/SELECTED_CATEGORY',
  CHANGE_SUB_CATEGORY = '@categories/CHANGE_SUB_CATEGORY',
  CHANGE_CATEGORY_PAGE = '@categories/CHANGE_CATEGORY_PAGE',
  CHANGE_CATEGORY_LIMIT_PER_PAGE = '@categories/CHANGE_CATEGORY_LIMIT_PER_PAGE',
  CHANGE_COUNT_CATEGORY = '@categories/CHANGE_COUNT_CATEGORY',
  CHANGE_PRODUCT_CATEGORY_PAGE = '@categories/CHANGE_PRODUCT_CATEGORY_PAGE',
  CHANGE_PRODUCT_CATEGORY_LIMIT_PER_PAGE = '@categories/CHANGE_PRODUCT_CATEGORY_LIMIT_PER_PAGE',
  CHANGE_COUNT_PRODUCT_CATEGORY = '@categories/CHANGE_COUNT_PRODUCT_CATEGORY',
}

export interface CategoryState {
  readonly selectedCategory?: PropsCategory | null;
  readonly subCategory: SubCategories | null;
  readonly categoryPage: number;
  readonly categoryLimitPerPage: number;
  readonly countCategory: number;
  readonly productByCategoryPage: number;
  readonly productByCategoryLimitPerPage: number;
  readonly countProductByCategory: number;
}
