import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as BranchActions from './actions';

import { BranchState, BranchTypes } from './types';

const INITIAL_STATE: BranchState = {
  selectedBranch: null,
  viewType: null,
  page: 1,
  limitPerPage: 10,
  count: 0,
};

type BranchReducers<Action extends AnyAction> = Reducer<BranchState, Action>;

const setSelectedBranch: BranchReducers<
  ReturnType<typeof BranchActions.setSelectedBranch>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedBranch: payload.data,
  viewType: payload.viewType,
});

const changePage: BranchReducers<
  ReturnType<typeof BranchActions.changePage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  page: payload.page,
});

const changeLimitPerPage: BranchReducers<
  ReturnType<typeof BranchActions.changeLimitPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  limitPerPage: payload.limitPerPage,
});

const changeCount: BranchReducers<
  ReturnType<typeof BranchActions.changeCount>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  count: payload.count,
});

const reducer = createReducer<BranchState>(INITIAL_STATE)
  .handleAction(BranchTypes.SELECTED_BRANCH, setSelectedBranch)
  .handleAction(BranchTypes.CHANGE_PAGE, changePage)
  .handleAction(BranchTypes.CHANGE_LIMIT_PER_PAGE, changeLimitPerPage)
  .handleAction(BranchTypes.CHANGE_COUNT, changeCount);

export default reducer;
