import { ProductsDTO } from '../../../dtos/auth/ProductsDTO';

export enum ProductTypes {
  SELECTED_PRODUCT = '@product/SELECTED_PRODUCT',
  SET_VIEW_TYPE_PRODUCT = '@product/SET_VIEW_TYPE_PRODUCT',
  CHANGE_TAB_INDEX = '@product/CHANGE_TAB_INDEX',
  CHANGE_PRODUCT_PAGE = '@product/CHANGE_PRODUCT_PAGE',
  CHANGE_PRODUCT_LIMIT_PER_PAGE = '@product/CHANGE_PRODUCT_LIMIT_PER_PAGE',
  CHANGE_COUNT_PRODUCT = '@product/CHANGE_COUNT_PRODUCT',
}

export interface ProductState {
  readonly selectedProduct?: ProductsDTO | null;
  readonly viewTypeProduct: 'view' | 'edit'; // view | edit
  readonly tabIndex: number;
  readonly productPage: number;
  readonly productLimitPerPage: number;
  readonly countProduct: number;
}
