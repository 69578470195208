import { PropsCategory, SubCategories } from '../../../dtos/auth/CategoriesDTO';

export enum FidelityTypes {
  SELECTED_FIDELITY_CATEGORY = '@fidelity/SELECTED_FIDELITY_CATEGORY',
  CHANGE_SUB_CATEGORY = '@fidelity/CHANGE_SUB_CATEGORY',
  CHANGE_FIDELITY_CATEGORY_PAGE = '@fidelity/CHANGE_FIDELITY_CATEGORY_PAGE',
  CHANGE_LIMIT_FIDELITY_CATEGORY_PER_PAGE = '@fidelity/CHANGE_LIMIT_FIDELITY_CATEGORY_PER_PAGE',
  CHANGE_COUNT_FIDELITY_CATEGORY = '@fidelity/CHANGE_COUNT_FIDELITY_CATEGORY',
  CHANGE_FIDELITY_CATEGORY_PRODUCT_PAGE = '@fidelity/CHANGE_FIDELITY_CATEGORY_PRODUCT_PAGE',
  CHANGE_LIMIT_FIDELITY_CATEGORY_PRODUCT_PER_PAGE = '@fidelity/CHANGE_LIMIT_FIDELITY_CATEGORY_PRODUCT_PER_PAGE',
  CHANGE_COUNT_FIDELITY_CATEGORY_PRODUCT = '@fidelity/CHANGE_COUNT_FIDELITY_CATEGORY_PRODUCT',
}

export interface FidelityState {
  readonly selectedFidelityCategory: PropsCategory | null;
  readonly subCategory: SubCategories | null;
  readonly fidelityCategoryPage: number;
  readonly limitFidelityCategoryPerPage: number;
  readonly countFidelityCategory: number;
  readonly fidelityCategoryProductPage: number;
  readonly limitFidelityCategoryProductPerPage: number;
  readonly countFidelityCategoryProduct: number;
}
