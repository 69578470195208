import { NotificationDTO } from '../../../dtos/auth/NotificationDTO';

export enum NotificationTypes {
  SELECTED_NOTIFICATION = '@notification/SELECTED_NOTIFICATION',
  CHANGE_PAGE_NOTIFICATION = '@notification/CHANGE_PAGE_NOTIFICATION',
  CHANGE_LIMIT_PER_PAGE_NOTIFICATION = '@notification/CHANGE_LIMIT_PER_PAGE_NOTIFICATION',
  CHANGE_COUNT_NOTIFICATION = '@notification/CHANGE_COUNT_NOTIFICATION',
}

export interface NotificationState {
  readonly selectedNotification: NotificationDTO | null;
  readonly viewType: string | null;
  readonly pageNotification: number;
  readonly limitPerPageNotification: number;
  readonly countNotification: number;
}
