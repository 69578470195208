import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';

import * as OrderActions from './actions';
import { OrderTypes, OrderState } from './types';

const INITIAL_STATE: OrderState = {
  selectedOrder: null,
  viewType: null,
  indexTab: null,
  pinFixed: false,
  productsOrder: null,
  page: 1,
  limitPerPage: 10,
  count: 0,
  tab: 0,
};

type OrderReducers<Action extends AnyAction> = Reducer<OrderState, Action>;

const setSelectedOrder: OrderReducers<
  ReturnType<typeof OrderActions.setSelectedOrder>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedOrder: payload.data,
  viewType: payload.viewType,
  indexTab: payload.indexTab,
});

const setProductsOrder: OrderReducers<
  ReturnType<typeof OrderActions.setProductsOrder>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  productsOrder: payload.data,
});

const changeFixePin: OrderReducers<
  ReturnType<typeof OrderActions.changeFixePin>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  pinFixed: payload.pinFixed,
});

const changePage: OrderReducers<ReturnType<typeof OrderActions.changePage>> = (
  state = INITIAL_STATE,
  { payload },
) => ({
  ...state,
  page: payload.page,
});

const changeLimitPerPage: OrderReducers<
  ReturnType<typeof OrderActions.changeLimitPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  limitPerPage: payload.limitPerPage,
});

const changeCount: OrderReducers<
  ReturnType<typeof OrderActions.changeCount>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  count: payload.count,
});

const changeTab: OrderReducers<ReturnType<typeof OrderActions.changeTab>> = (
  state = INITIAL_STATE,
  { payload },
) => ({
  ...state,
  tab: payload.tab,
});

const reducer = createReducer<OrderState>(INITIAL_STATE)
  .handleAction(OrderTypes.SELECTED_ORDER, setSelectedOrder)
  .handleAction(OrderTypes.PRODUCTS_ORDER, setProductsOrder)
  .handleAction(OrderTypes.PIN_FIXED, changeFixePin)
  .handleAction(OrderTypes.CHANGE_PAGE, changePage)
  .handleAction(OrderTypes.CHANGE_LIMIT_PER_PAGE, changeLimitPerPage)
  .handleAction(OrderTypes.CHANGE_COUNT, changeCount)
  .handleAction(OrderTypes.CHANGE_TAB, changeTab);

export default reducer;
