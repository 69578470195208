import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    primary: '#FA2458',
    primaryVariant: '#FF406E',
    primaryLight: '#FF979C',

    red: '#FC4A4E',
    lightRed: '#FF979C',
    lighterRed: '#FF979C',
    pink: '#FF979C',
    green: '#59C7A3',
    lightGreen: '#BFEACF',
    yellow: '#FED278',
    lightYellow: '#FAFAF2',

    grey: '#BDBCBC',
    darkGrey: '#656263',
    darkerGrey: '#231F20',
    lightGrey: '#D3D3D3',
    lighterGrey: '#EDEDED',
    lightestGrey: '#F8F8F8',

    white: '#FFFFFF',
  },
};

export default theme;
