import { darken, lighten, linearGradient } from 'polished';
import { createGlobalStyle } from 'styled-components';
import Calendar from '../assets/icons/calendar.svg';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;

    ::-webkit-scrollbar {
      width: 12px;
      height: 12px;
      background-color: none;
      border-radius: 4px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.grey};
      border-radius: 5px;
    }

    ::placeholder {
      color: ${(props) => props.theme.colors.lightGrey} !important;
      }

    :-ms-input-placeholder {
      color: ${(props) => props.theme.colors.lightGrey} !important;
    }

    .button-nowraped {
      white-space: nowrap !important;
    }

    .selected_order-chat {
      :hover {
        box-shadow: 3px 3px 6px 3px ${(props) =>
          props.theme.colors.lightGreen} !important;
      }
    }
  }




  *:focus {
    outline: 0;
  }

  body {
    -webkit-font-smoothing: antialiased;
    margin-top: -7px !important;

    #tabs-content {
      max-height: 100% !important;
      overflow-y: auto !important;
    }

    .tabs-content {
      max-height: 100% !important;
      overflow-y: auto !important;
    }

    .change-height {
      height: calc(100vh - 145px);

      @media screen and (max-height: 700px) {
        height: calc(100vh - 115px) !important;
      }
    }

  }

  body,input,button,img,textarea,select,option {
    font: 16px 'Bree Serif', serif, sans-serif;
  }

  html,body,#root{
    height: 100%;
    margin: 0 0 0 0 !important;
  }

  img {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    -ms-user-select: none;
  }

  button {
    border: 0;
    cursor: pointer;
    background: none;
  }

  /* ------------- react tabs ---------------- */
  .react-tabs {
    width: 100%;
    height: 100%;
    max-height: 100% !important;

    .icon-erro {
      position: absolute;
      top: 0;
      right: 0;
    }

    .react-tabs__tab-list {
      white-space: nowrap !important;
      background-color: transparent;
      border-bottom: 1px solid ${(props) => props.theme.colors.primary};

    }

    .react-tabs__tab {
      border: none;
      width: 180px;
      text-align: center;
      padding: 0px;
      background-color: transparent;

    }

    .react-tabs__tab--selected {
      color: ${(props) => props.theme.colors.primary};


      .selected-element {
        margin-top: 5px;
        border-top: 5px solid ${(props) => props.theme.colors.primary};
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

      }

      ::after {
        content: none;
        background-color: blue;
      }
    }

    .react-tabs__tab-panel {
      width: 100%;
      max-height: 100% !important;
      /* overflow-y: auto !important; */

      padding-top: 24px;
      margin-bottom: 24px;

      /* + div {
        max-height: 100% !important;
        overflow-y: auto;
      } */
    }

    /* #tabs-content-order-manager {
      max-height: 100% !important;
    } */

    .react-tabs__tab--disabled {
      color: ${(props) => props.theme.colors.lighterGrey} !important;
    }
  }
  /* --------------- ---------------- */



  /* ---------------- react table ------------- */
  .rdt_Table {
    height: fit-content;
    max-height: calc(100vh - 270px);

    @media screen and (max-height: 700px) {
      max-height: calc(100vh - 250px);
    }

  }

  .rdt_TableHead {
    color: ${(props) => props.theme.colors.darkerGrey} !important;
    font-size: 16px !important;
  }

  .rdt_TableRow{
    border-bottom: none !important;
    /* max-width: 70%; */
    font-family: 'Noto sans';
    font-size: 14px !important;
  }

  .rdt_Pagination{
    color: #000000;
    svg{
      fill: #000000;
    }
  }

  #pagination-previous-page, #pagination-next-page{
    flex: 1;
    color: #000000;
    background: #FF979C;
    border-radius: 5px;
    padding: 10px;
    margin: 0px;
    margin-right: 10px;

  }

   /* ----------------  ------------- */

  #error-message{
    color: red !important;
  }

  .overlay-products-combo {
    background-color: rgba(0, 0, 0, 0.45);

    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 1;
  }

  .content-products-combo {
    width: 100%;
    max-width: 700px;
    background: ${(props) => props.theme.colors.white};
    padding: 32px;
    border-radius: 8px;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    /* content: url('../assets/icons/calendar.svg'); */
    width: 23px;
    height: 23px;
  }

  input[type="time"]::-webkit-calendar-picker-indicator {
    width: 26px;
    height: 26px;
  }

  /* ----------------------------- fullCalledar -----------------------------*/
  .fc {
    width: 100%;
    height: fit-content !important;
    flex-direction: row !important;
    align-items: flex-start;
    overflow: auto;



    /* --- general custons header--- */

    .fc-header-toolbar {
      min-width: 300px;
      height: auto !important;
      align-items: top;
      flex-direction: column;
      margin-right: 20px;

    }

    .fc-toolbar-chunk {
        width: 100%;
        display: flex;
        justify-content: space-between;
          align-items: center;
        div {
          min-height: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-bottom: 10px;
         }
      }

      .fc-button-group {

      }

      /* -----butons----- */
      .fc-button{
        background-color: transparent !important;
        color: ${(props) => props.theme.colors.primary} !important;
        border: none !important;

        :hover {
          background-color: ${(props) =>
            lighten(0.4, props.theme.colors.primary)} !important;
        }

        :focus {
          box-shadow: none !important;
        }

      }
      .fc-dayGridWeek-button, .fc-dayGridDay-button, .fc-today-button {
        max-width: 50% !important;
        border: 1px solid ${(props) => props.theme.colors.primary} !important;

        :focus {
          color: ${(props) => props.theme.colors.white} !important;
          background-color: ${(props) => props.theme.colors.primary} !important;
        }
      }

      .fc-toolbar-title {
        font-size: 18px !important;
        color: ${(props) => props.theme.colors.darkerGrey};
        text-align: center;
      }

      .fc-col-header-cell-cushion  {
        width: 100%;
      }
      .fc-daygrid-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

    /* -----general table----- */
    table {

      border-collapse: collapse;
      border: none !important;
      border-left: 1px solid ${(props) =>
        props.theme.colors.lightGrey} !important;

      th, tr, td {
        border: none;
      }

      th, td {
        font-weight: normal;
        border-right: 1px solid ${(props) =>
          props.theme.colors.lightGrey} !important;
      }

    }

    .fc-day-today {
      background-color: ${(props) =>
        lighten(0.42, props.theme.colors.primary)} !important;
    }

    .fc-view-harness {
      height: 100% !important;
      min-height: 75vh !important;
      width: 100%;
      border: none !important;
      color: ${(props) => props.theme.colors.darkGrey};
    }

    /* .fc-view-harness-active {
      min-height: 600px !important;
    } */

    .fc-col-header-cell-cushion {
        margin-bottom: 10px;
    }

    /* ----- event ----- */

    .fc-daygrid-day-events{

    }

    .fc-daygrid-event-harness {
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between !important;
      max-width: 100%;
      margin: 0px 5px 15px 5px;
      border-bottom: 1px solid ${(props) =>
        props.theme.colors.lightGrey} !important;
    }

    .fc-daygrid-event {
      display: flex;
      flex-direction: row;
      width: 100%;
      overflow: hidden;
      margin-bottom: 5px;
      /* padding: 0 15px; */

      align-items: center;
      justify-content: space-between !important;

      :hover {
          background-color: ${(props) =>
            lighten(0.4, props.theme.colors.primary)} !important;
        }
    };


    .fc-daygrid-event-dot {
      border-color: ${(props) => props.theme.colors.primary};
    }

    .fc-daygrid-time {
      font-family: "Noto Sans";
      font-size: 12px;
      width: fit-content;
      align-items: center;
      justify-content: center;
    }

    .fc-daygrid-title {
      font-family: "Noto Sans";
      font-size: 12px;
    }

    /*  custom  */

    .fc-daygrid-ifen{
      margin: 0 3px;
    }


    /* ----event day view custom---- */

    .fc-day-event {
      width: 100%;
      padding: 0px 15px;
    }

    .fc-day-time {
      font-size: 20px;
      color: ${(props) => props.theme.colors.primary};
      margin-bottom: 20px;

    }

    .fc-day-title {
      font-size: 16px;
      padding: 5px 0px;
      color: ${(props) => props.theme.colors.primary};
    }

    .fc-day-event-harness {

    }

    .fc-day-products {
      border-top: 1px solid ${(props) =>
        props.theme.colors.lightGrey} !important;
      border-bottom: 1px solid ${(props) =>
        props.theme.colors.lightGrey} !important;
    }

    .fc-day-product {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-family: "Noto Sans";
      font-size: 14px;
      padding: 5px;
    }

    .fc-day-product-total-price {
      color: ${(props) => props.theme.colors.primary};
      font-size: 18px;
    }

    .fc-day-obs {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: 10px;

    }


    /* ---- custom colum header ---- */

    .fc-daygrid-day-header {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .fc-daygrid-day-header-text {
      margin-right: 5px;
    }

    .fc-daygrid-day-header-number {
      margin-left: 20px;
      font-size: 26px;
      font-family: "Roboto", sans-serif;
    }

    .fc-daygrid-weekday {
      cursor: pointer;
      margin: 0;
    }

    .fc-daygrid-weekday-header {
      display: flex;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .fc-daygrid-weekday-header-number {
      width: 40px;
      height: 40px;
      margin: 5px;
      font-size: 22px;
      border-radius: 10px;
      font-family: "Roboto", sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .fc-day-today .fc-daygrid-weekday-header-number {

      color: ${(props) => props.theme.colors.white};
      background-color: ${(props) => props.theme.colors.primary} !important;
    }

  }
  /* -----------------------------  -----------------------------*/

    /* ----------------------------- react-toastify -----------------------------*/
  .Toastify__toast-theme--colored.Toastify__toast--info {
    background-color: ${(props) => props.theme.colors.primary};
  /* background: linear-gradient(180deg, #FA2458 0%, #FA2458 70%, #FED278 100%) */
  }

  /* -----------------------------  -----------------------------*/

  `;
