export enum CouponTypes {
  SELECTED_COUPON_ID = '@coupons/SELECTED_COUPON_ID',
  SET_VIEW_TYPE_COUPON = '@coupons/SET_VIEW_TYPE_COUPON',
  CHANGE_COUPON_PAGE = '@coupons/CHANGE_COUPON_PAGE',
  CHANGE_COUPON_LIMIT_PER_PAGE = '@coupons/CHANGE_COUPON_LIMIT_PER_PAGE',
  CHANGE_COUNT_COUPON = '@coupons/CHANGE_COUNT_COUPON',
  CHANGE_PRODUCT_PAGE = '@coupons/CHANGE_PRODUCT_PAGE',
  CHANGE_PRODUCT_LIMIT_PER_PAGE = '@coupons/CHANGE_PRODUCT_LIMIT_PER_PAGE',
  CHANGE_COUNT_PRODUCT = '@coupons/CHANGE_COUNT_PRODUCT',
}

export interface CouponState {
  readonly selectedCouponId: string | null;
  readonly viewTypeCoupon: string | null; // create | view | edit
  readonly couponPage: number;
  readonly couponLimitPerPage: number;
  readonly countCoupon: number;
  readonly productPage: number;
  readonly productLimitPerPage: number;
  readonly countProduct: number;
}
