import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  width: 100%;
  padding-top: 32px;
  flex: 1;
  margin: 0 auto;
  min-height: calc(100% - 79px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 1370px) {
    justify-content: start;
    padding-top: 18px;
  }
`;

export const Logo = styled.img`
  width: 160px;
  margin-bottom: 56px;

  @media screen and (max-width: 1370px) {
    margin-bottom: 10px;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 79px;
  background-color: ${(props) => props.theme.colors.primary};
  border-bottom: 15px solid ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const Card = styled.div`
  background-color: white;
  border-radius: 30px;
  padding: 36px 47px;
  box-shadow: 0px 10px 20px rgba(116, 76, 39, 0.32);

  @media screen and (max-width: 600px) {
    padding: 0px;
    box-shadow: 0px 0px 0px #fff;
  }

  @media screen and (max-width: 1370px) {
    padding: 10px 30px;
  }
`;

export const SignInForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    margin-top: 45px;
  }
`;

export const Button = styled.button`
  width: 100%;
  margin-top: 43px;

  padding: 18px;
  border-radius: 56px;
  background-color: ${(props) => props.theme.colors.primary};

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 18px;
  color: ${(props) => props.theme.colors.white};

  &:hover {
    background-color: ${(props) => darken(0.1, props.theme.colors.primary)};
  }
`;

export const RecoverPasswordLink = styled.p`
  margin-top: 42px;
  font-size: 18px;
  text-align: center;

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.pink};
  }
`;
