import axios from 'axios';
import { toast } from 'react-toastify';
import store from '../store/index';

import { logOut, setToken } from '../store/ducks/auth/actions';

import { ApplicationState } from '../store/types';

interface RefreshTokenReturn {
  token: string;
}

export const BASE_URL = process.env.REACT_APP_BASEURL
  ? `${process.env.REACT_APP_BASEURL}/api/`
  : '/api/';

const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalConfig = error.config;
    const state = store.getState() as ApplicationState;
    const { data } = state.auth;

    if (error.response) {
      if (
        error.response.data.message === 'Refresh token inválido.' ||
        error.response.data.message === 'Sessão expirada.'
      ) {
        store.dispatch(logOut());
        return toast.warning('Sua sessão expirou. Realize o login novamente!');
      }
      if (
        error.response.status === 401 &&
        (error.response.data.message ===
          'Acesso negado: Token de autenticação inexistente.' ||
          error.response.data.message === 'Acesso negado: Token inválido.')
      ) {
        try {
          const refresh_token = data && data.refresh_token;

          const response = await api.post<RefreshTokenReturn>(
            '/auth/refresh-token',
            { refresh_token },
          );

          api.defaults.headers.common.Authorization = `Bearer ${response.data?.token}`;

          store.dispatch(setToken(response.data?.token));

          return api(originalConfig);
        } catch (err) {
          return store.dispatch(logOut());
        }
      }
    }
    return Promise.reject(error);
  },
);

api.interceptors.request.use(
  (config) => {
    const state = store.getState() as ApplicationState;
    const { data } = state.auth;

    if (data?.token) {
      config.headers = {
        Authorization: `Bearer ${data?.token}`,
      };
    }

    return config;
  },
  (erro) => {
    if (erro) {
      // console.log('erro', erro);
    }

    return Promise.reject(erro);
  },
);

export default api;
