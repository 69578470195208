import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { all, takeLatest, call, put, StrictEffect } from 'redux-saga/effects';

import { loginSuccess, loginError, loginRequest } from './actions';

import { AuthTypes } from './types';
import AuthDTO from '../../../dtos/auth/AuthDTO';

import AuthAPI from '../../../libs/api/auth';
import api from '../../../services/api';
import { PersistPayload } from '../../types';

type LoginParams = ReturnType<typeof loginRequest>;
function* login({
  payload,
}: LoginParams): Generator<StrictEffect, any, AuthDTO> {
  try {
    const { data } = payload;
    const response = yield call(AuthAPI.login, data);

    api.defaults.headers.common.Authorization = `Bearer ${response.token}`;

    yield put(loginSuccess(response));
    toast.success('Login efetuado com sucesso!');
  } catch (err) {
    const error = err as Error | AxiosError;
    let messageErro = '';

    if (axios.isAxiosError(error)) {
      if (error.response) {
        messageErro = error.response.data.message;
      } else {
        messageErro = 'Não foi possível realizar o login';
      }
    } else {
      messageErro = 'Ocorreu um problema ao realizar o login!';
    }

    toast.error(messageErro);

    yield put(loginError());
  }
}

export function setToken({ payload }: PersistPayload) {
  if (!payload) {
    return;
  }

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
}

export default all([
  takeLatest<any>(AuthTypes.PERSIST_REHYDRATATE, setToken),
  takeLatest(AuthTypes.LOGIN_REQUEST, login),
]);
