import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as OffersActions from './actions';

import { OfferState, OfferTypes } from './types';

const INITIAL_STATE: OfferState = {
  selectedOffer: null,
  viewType: null,
  page: 1,
  limitPerPage: 10,
  count: 0,
  pageProductsInOffers: 1,
  limitPerPageProductsInOffers: 10,
  countProductsInOffers: 0,
};

type OfferReducers<Action extends AnyAction> = Reducer<OfferState, Action>;

const setSelectedOffer: OfferReducers<
  ReturnType<typeof OffersActions.setSelectedOffer>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedOffer: payload.data,
  viewType: payload.viewType,
});

const changePage: OfferReducers<ReturnType<typeof OffersActions.changePage>> = (
  state = INITIAL_STATE,
  { payload },
) => ({
  ...state,
  page: payload.page,
});

const changeLimitPerPage: OfferReducers<
  ReturnType<typeof OffersActions.changeLimitPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  limitPerPage: payload.limitPerPage,
});

const changeCount: OfferReducers<
  ReturnType<typeof OffersActions.changeCount>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  count: payload.count,
});

const changePageProductsInOffers: OfferReducers<
  ReturnType<typeof OffersActions.changePageProductsInOffers>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  pageProductsInOffers: payload.pageProductsInOffers,
});

const changeLimitPerPageProductsInOffers: OfferReducers<
  ReturnType<typeof OffersActions.changeLimitPerPageProductsInOffers>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  limitPerPageProductsInOffers: payload.limitPerPageProductsInOffers,
});

const changeCountProductsInOffers: OfferReducers<
  ReturnType<typeof OffersActions.changeCountProductsInOffers>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  countProductsInOffers: payload.countProductsInOffers,
});

const reducer = createReducer<OfferState>(INITIAL_STATE)
  .handleAction(OfferTypes.SELECTED_OFFER, setSelectedOffer)
  .handleAction(OfferTypes.CHANGE_PAGE, changePage)
  .handleAction(OfferTypes.CHANGE_LIMIT_PER_PAGE, changeLimitPerPage)
  .handleAction(OfferTypes.CHANGE_COUNT, changeCount)
  .handleAction(
    OfferTypes.CHANGE_PAGE_PRODUCTS_IN_OFFERS,
    changePageProductsInOffers,
  )
  .handleAction(
    OfferTypes.CHANGE_LIMIT_PER_PAGE_PRODUCTS_IN_OFFERS,
    changeLimitPerPageProductsInOffers,
  )
  .handleAction(
    OfferTypes.CHANGE_COUNT_PRODUCTS_IN_OFFERS,
    changeCountProductsInOffers,
  );

export default reducer;
