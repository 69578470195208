import React from 'react';
import { Container, Card, Header, Logo } from './styles';

import logo from '../../../assets/logo2.svg';

const PublicLayout: React.FC = ({ children }) => {
  return (
    <>
      <Header />
      <Container>
        <Logo src={logo} />

        <Card>{children}</Card>
      </Container>
    </>
  );
};

export default PublicLayout;
