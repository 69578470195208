import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';

import * as PlanActions from './actions';
import { PlanState, PlanTypes } from './types';

const INITIAL_STATE: PlanState = {
  selectedPlan: null,
  viewTypePlan: 'create',
};

type PlanReducers<Action extends AnyAction> = Reducer<PlanState, Action>;

const setSelectedPlan: PlanReducers<
  ReturnType<typeof PlanActions.setSelectedPlan>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedPlan: payload.dataPlan,
});

const changeViewTypePlan: PlanReducers<
  ReturnType<typeof PlanActions.changeViewTypePlan>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  viewTypePlan: payload.viewTypePlan,
});

const reducer = createReducer<PlanState>(INITIAL_STATE)
  .handleAction(PlanTypes.SELECTED_PLAN, setSelectedPlan)
  .handleAction(PlanTypes.CHANGE_VIEW_TYPE_PLAN, changeViewTypePlan);

export default reducer;
