import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import auth from './auth';
import user from './user';
import orders from './orders';
import products from './products';
import clients from './clients';
import plans from './plans';
import categories from './categories';
import contributors from './contributors';
import faq from './faq';
import combos from './combos';
import fidelity from './fidelity';
import campaigns from './campaigns';
import role from './role';
import notifications from './notifications';
import coupons from './coupons';
import contact from './contactUs';
import branches from './branches';
import advertisement from './advertisement';
import offers from './offers';
import fees from './fees';
import paymentsMethods from './paymentsMethods';

const rootReducer = combineReducers({
  auth,
  user,
  products,
  orders,
  contributors,
  categories,
  clients,
  plans,
  faq,
  combos,
  fidelity,
  campaigns,
  role,
  notifications,
  coupons,
  contact,
  branches,
  advertisement,
  offers,
  fees,
  paymentsMethods,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'auth',
    'products',
    'orders',
    'contributors',
    'user',
    'faq',
    'categories',
    'clients',
    'combos',
    'fidelity',
    'campaigns',
    'role',
    'notifications',
    'coupons',
    'contact',
    'branches',
    'advertisement',
    'offers',
    'fees',
    'paymentsMethods',
  ],
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);
