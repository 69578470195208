import { action } from 'typesafe-actions';
import { UserTypes } from './types';
import { UserDTO } from '../../../dtos/auth/UserDTO';

export const loadProfileData = () => {
  return action(UserTypes.LOAD_PROFILE_DATA);
};

export const setProfileData = (profileData: UserDTO) =>
  action(UserTypes.SET_PROFILE_DATA, { profileData });
