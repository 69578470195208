import { UserDTO } from '../../../dtos/auth/UserDTO';

export enum UserTypes {
  LOAD_PROFILE_DATA = '@auth/LOAD_PROFILE_DATA',
  SET_PROFILE_DATA = '@auth/SET_PROFILE_DATA',
}

export interface UserState {
  readonly profileData?: UserDTO | null;
}
