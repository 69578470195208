import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';

import * as FidelityActions from './actions';
import { FidelityTypes, FidelityState } from './types';

const INITIAL_STATE: FidelityState = {
  selectedFidelityCategory: null,
  subCategory: null,
  fidelityCategoryPage: 1,
  limitFidelityCategoryPerPage: 10,
  countFidelityCategory: 0,
  fidelityCategoryProductPage: 1,
  limitFidelityCategoryProductPerPage: 10,
  countFidelityCategoryProduct: 0,
};

type FidelityReducers<Action extends AnyAction> = Reducer<
  FidelityState,
  Action
>;

const setSelectedFidelityCategory: FidelityReducers<
  ReturnType<typeof FidelityActions.setSelectedFidelityCategory>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedFidelityCategory: payload.fidelityCategory,
});

const changeSubCategory: FidelityReducers<
  ReturnType<typeof FidelityActions.changeSubCategory>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  subCategory: payload.subCategory,
});

const changeFidelityCategoryPage: FidelityReducers<
  ReturnType<typeof FidelityActions.changeFidelityCategoryPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  fidelityCategoryPage: payload.fidelityCategoryPage,
});

const changeLimitFidelityCategoryPerPage: FidelityReducers<
  ReturnType<typeof FidelityActions.changeLimitFidelityCategoryPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  limitFidelityCategoryPerPage: payload.limitFidelityCategoryPerPage,
});

const changeCountFidelityCategoryProduct: FidelityReducers<
  ReturnType<typeof FidelityActions.changeCountFidelityCategoryProduct>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  countFidelityCategory: payload.countFidelityCategory,
});

const changeFidelityCategoryProductPage: FidelityReducers<
  ReturnType<typeof FidelityActions.changeFidelityCategoryProductPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  fidelityCategoryProductPage: payload.fidelityCategoryProductPage,
});

const changeLimitFidelityCategoryProductPerPage: FidelityReducers<
  ReturnType<typeof FidelityActions.changeLimitFidelityCategoryProductPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  limitFidelityCategoryProductPerPage:
    payload.limitFidelityCategoryProductPerPage,
});

const changeCountFidelityCategoryProductList: FidelityReducers<
  ReturnType<typeof FidelityActions.changeCountFidelityCategoryProductList>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  countFidelityCategoryProduct: payload.countFidelityCategoryProduct,
});

const reducer = createReducer<FidelityState>(INITIAL_STATE)
  .handleAction(
    FidelityTypes.SELECTED_FIDELITY_CATEGORY,
    setSelectedFidelityCategory,
  )
  .handleAction(FidelityTypes.CHANGE_SUB_CATEGORY, changeSubCategory)
  .handleAction(
    FidelityTypes.CHANGE_FIDELITY_CATEGORY_PAGE,
    changeFidelityCategoryPage,
  )
  .handleAction(
    FidelityTypes.CHANGE_LIMIT_FIDELITY_CATEGORY_PER_PAGE,
    changeLimitFidelityCategoryPerPage,
  )
  .handleAction(
    FidelityTypes.CHANGE_COUNT_FIDELITY_CATEGORY,
    changeCountFidelityCategoryProduct,
  )
  .handleAction(
    FidelityTypes.CHANGE_FIDELITY_CATEGORY_PRODUCT_PAGE,
    changeFidelityCategoryProductPage,
  )
  .handleAction(
    FidelityTypes.CHANGE_LIMIT_FIDELITY_CATEGORY_PRODUCT_PER_PAGE,
    changeLimitFidelityCategoryProductPerPage,
  )
  .handleAction(
    FidelityTypes.CHANGE_COUNT_FIDELITY_CATEGORY_PRODUCT,
    changeCountFidelityCategoryProductList,
  );

export default reducer;
