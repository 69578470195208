import { OfferDTO } from '../../../dtos/auth/OffersDTO';

export enum OfferTypes {
  SELECTED_OFFER = '@offers/SELECTED_NOFFER',
  CHANGE_PAGE = '@offers/CHANGE_PAGE',
  CHANGE_LIMIT_PER_PAGE = '@offers/CHANGE_LIMIT_PER_PAGE',
  CHANGE_COUNT = '@offers/CHANGE_COUNT',
  CHANGE_PAGE_PRODUCTS_IN_OFFERS = '@offers/CHANGE_PAGE_PRODUCTS_IN_OFFERS',
  CHANGE_LIMIT_PER_PAGE_PRODUCTS_IN_OFFERS = '@offers/CHANGE_LIMIT_PER_PAGE_PRODUCTS_IN_OFFERS',
  CHANGE_COUNT_PRODUCTS_IN_OFFERS = '@offers/CHANGE_COUNT_PRODUCTS_IN_OFFERS',
}

export interface OfferState {
  readonly selectedOffer: OfferDTO | null;
  readonly viewType: string | null;
  readonly page: number;
  readonly limitPerPage: number;
  readonly count: number;
  readonly pageProductsInOffers: number;
  readonly limitPerPageProductsInOffers: number;
  readonly countProductsInOffers: number;
}
