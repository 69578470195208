import { RoleDTO } from '../../../dtos/auth/RolesDTO';

export enum RoleTypes {
  SELECTED_ROLE = '@role/SELECTED_ROLE',
  VIEW_TYPE_ROLE = '@role/VIEW_TYPE_ROLE',
  CHANGE_ROLE_PAGE = '@role/CHANGE_ROLE_PAGE',
  CHANGE_ROLE_LIMIT_PER_PAGE = '@role/CHANGE_ROLE_LIMIT_PER_PAGE',
  CHANGE_COUNT_ROLE = '@role/CHANGE_COUNT_ROLE',
  CHANGE_USERS_ROLE_PAGE = '@role/CHANGE_USERS_ROLE_PAGE',
  CHANGE_USERS_ROLE_LIMIT_PER_PAGE = '@role/CHANGE_USERS_ROLE_LIMIT_PER_PAGE',
  CHANGE_COUNT_USERS_ROLE = '@role/CHANGE_COUNT_USERS_ROLE',
}

export interface RoleState {
  readonly selectedRole: RoleDTO | null;
  readonly viewTypeRole: string | null; // create | view | edit
  readonly rolePage: number;
  readonly roleLimitPerPage: number;
  readonly countRole: number;
  readonly usersRolePage: number;
  readonly usersRoleLimitPerPage: number;
  readonly countUsersRole: number;
}
