import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';

import * as MessageContactActions from './actions';
import { MessageContactTypes, MessageContactState } from './types';

const INITIAL_STATE: MessageContactState = {
  selectedMessageContactId: null,
  viewTypeMessageContact: 'view',
  contactUsPage: 1,
  contactUsLimitPerPage: 10,
  countContactUs: 0,
};

type MessageContactReducers<Action extends AnyAction> = Reducer<
  MessageContactState,
  Action
>;

const setSelectedMessageContactId: MessageContactReducers<
  ReturnType<typeof MessageContactActions.setSelectedMessageContactId>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedMessageContactId: payload.messageContactId,
});

const setViewTypeMessageContact: MessageContactReducers<
  ReturnType<typeof MessageContactActions.setViewTypeMessageContact>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  viewTypeMessageContact: payload.viewTypeMessageContact,
});

const changeContactUsPage: MessageContactReducers<
  ReturnType<typeof MessageContactActions.changeContactUsPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  contactUsPage: payload.contactUsPage,
});

const changeContactUsLimitPerPage: MessageContactReducers<
  ReturnType<typeof MessageContactActions.changeContactUsLimitPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  contactUsLimitPerPage: payload.contactUsLimitPerPage,
});

const changeCountContactUs: MessageContactReducers<
  ReturnType<typeof MessageContactActions.changeCountContactUs>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  countContactUs: payload.countContactUs,
});

const reducer = createReducer<MessageContactState>(INITIAL_STATE)
  .handleAction(
    MessageContactTypes.SELECTED_MESSAGE_CONTACT_ID,
    setSelectedMessageContactId,
  )
  .handleAction(
    MessageContactTypes.SET_VIEW_TYPE_MESSAGE_CONTACT,
    setViewTypeMessageContact,
  )
  .handleAction(MessageContactTypes.CHANGE_CONTACT_US_PAGE, changeContactUsPage)
  .handleAction(
    MessageContactTypes.CHANGE_CONTACT_US_LIMIT_PER_PAGE,
    changeContactUsLimitPerPage,
  )
  .handleAction(
    MessageContactTypes.CHANGE_COUNT_CONTACT_US,
    changeCountContactUs,
  );

export default reducer;
