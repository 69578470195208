import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';
import { AuthState, AuthTypes } from './types';
import * as AuthActions from './actions';

const INITIAL_STATE: AuthState = {
  data: null,
  token: null,
  error: false,
  loading: false,
};

type AuthReducers<Action extends AnyAction> = Reducer<AuthState, Action>;

const loginRequest: AuthReducers<
  ReturnType<typeof AuthActions.loginRequest>
> = (state = INITIAL_STATE) => ({ ...state, loading: true });

const loginSuccess: AuthReducers<
  ReturnType<typeof AuthActions.loginSuccess>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  data: payload.data,
  token: payload.data.token,
  loading: false,
});

const loginError: AuthReducers<ReturnType<typeof AuthActions.loginError>> = (
  state = INITIAL_STATE,
) => ({ ...state, loading: false });

const logOut: AuthReducers<ReturnType<typeof AuthActions.logOut>> = () => ({
  ...INITIAL_STATE,
});

const setToken: AuthReducers<ReturnType<typeof AuthActions.setToken>> = (
  state = INITIAL_STATE,
  { payload },
) => ({
  ...state,
  data: state.data ? { ...state.data, token: payload.token } : null,
});

const reducer = createReducer<AuthState>(INITIAL_STATE)
  .handleAction(AuthTypes.LOGIN_REQUEST, loginRequest)
  .handleAction(AuthTypes.LOGIN_SUCCCES, loginSuccess)
  .handleAction(AuthTypes.LOGIN_ERROR, loginError)
  .handleAction(AuthTypes.SET_TOKEN, setToken)
  .handleAction(AuthTypes.LOGOUT, logOut);

export default reducer;
