import { FormikOrderProductDTO, OrderDTO } from '../../../dtos/auth/OrdersDTO';

export enum OrderTypes {
  SELECTED_ORDER = '@orders/SELECTED_ORDER',
  PIN_FIXED = '@orders/PIN_FIXED',
  PRODUCTS_ORDER = '@orders/PRODUCTS_ORDER',
  CHANGE_PAGE = '@orders/CHANGE_PAGE',
  CHANGE_LIMIT_PER_PAGE = '@orders/CHANGE_LIMIT_PER_PAGE',
  CHANGE_COUNT = '@orders/CHANGE_COUNT',
  CHANGE_TAB = '@orders/CHANGE_TAB',
}

export interface OrderState {
  readonly selectedOrder?: OrderDTO | null;
  readonly viewType?: string | null;
  readonly indexTab?: number | null;
  readonly pinFixed: boolean;
  readonly productsOrder?: FormikOrderProductDTO[] | null;
  readonly page: number;
  readonly limitPerPage: number;
  readonly count: number;
  readonly tab: number;
}
