import { FaqDTO } from '../../../dtos/auth/FaqDTO';

export enum FAQTypes {
  SELECTED_FAQ = '@faq/SELECTED_FAQ',
  SELECTED_VIEW_TYPE_FAQ = '@faq/SELECTED_VIEW_TYPE_FAQ',
  CHANGE_FAQ_PAGE = '@faq/CHANGE_FAQ_PAGE',
  CHANGE_FAQ_LIMIT_PER_PAGE = '@faq/CHANGE_FAQ_LIMIT_PER_PAGE',
  CHANGE_COUNT_FAQ = '@faq/CHANGE_COUNT_FAQ',
}

export interface FAQState {
  readonly selectedFAQ?: FaqDTO | null;
  readonly viewTypeFaq: string; // "register" | "edit" | "view" -> default is "register"
  readonly faqPage: number;
  readonly faqLimitPerPage: number;
  readonly countFaq: number;
}
