import { action } from 'typesafe-actions';
import { AuthTypes } from './types';
import AuthDTO from '../../../dtos/auth/AuthDTO';
import LoginDTO from '../../../dtos/auth/LoginDTO';

export const loginRequest = (data: LoginDTO) =>
  action(AuthTypes.LOGIN_REQUEST, { data });

export const loginSuccess = (data: AuthDTO) =>
  action(AuthTypes.LOGIN_SUCCCES, { data });

export const loginError = () => action(AuthTypes.LOGIN_ERROR);

export const logOut = () => action(AuthTypes.LOGOUT);

export const setToken = (token: string) =>
  action(AuthTypes.SET_TOKEN, { token });
