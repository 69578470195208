import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';

import * as ClientActions from './actions';
import { ClientState, ClientTypes } from './types';

const INITIAL_STATE: ClientState = {
  selectedClientId: null,
  selectedOrderHistory: null,
  viewTypeClient: 'create',
  tabIndex: 0,
  fidelityClientSelected: null,
  clientPage: 1,
  clientLimitPerPage: 10,
  countClient: 0,
  fidelityClientPage: 1,
  fidelityClientLimitPerPage: 10,
  countFidelityClient: 0,
  orderHistoryClientPage: 1,
  orderHistoryClientLimitPerPage: 10,
  countOrderHistoryClient: 0,
};

type ClientReducers<Action extends AnyAction> = Reducer<ClientState, Action>;

const setSelectedClient: ClientReducers<
  ReturnType<typeof ClientActions.setSelectedClient>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedClientId: payload.selectedClientId,
});

const setSelectedOrderHistory: ClientReducers<
  ReturnType<typeof ClientActions.setSelectedOrderHistory>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedOrderHistory: payload.selectedOrderHistory,
});

const setViewTypeClient: ClientReducers<
  ReturnType<typeof ClientActions.setViewTypeClient>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  viewTypeClient: payload.viewTypeClient,
});

const changeTabIndex: ClientReducers<
  ReturnType<typeof ClientActions.changeTabIndex>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  tabIndex: payload.tabIndex,
});

const setFidelityClientSelected: ClientReducers<
  ReturnType<typeof ClientActions.setFidelityClientSelected>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  fidelityClientSelected: payload.fidelityClientSelected,
});

const changeClientPage: ClientReducers<
  ReturnType<typeof ClientActions.changeClientPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  clientPage: payload.clientPage,
});

const changeClientLimitPerPage: ClientReducers<
  ReturnType<typeof ClientActions.changeClientLimitPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  clientLimitPerPage: payload.clientLimitPerPage,
});

const changeCountClient: ClientReducers<
  ReturnType<typeof ClientActions.changeCountClient>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  countClient: payload.countClient,
});

const changeFidelityClientPage: ClientReducers<
  ReturnType<typeof ClientActions.changeFidelityClientPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  fidelityClientPage: payload.fidelityClientPage,
});

const changeFidelityClientLimitPerPage: ClientReducers<
  ReturnType<typeof ClientActions.changeFidelityClientLimitPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  fidelityClientLimitPerPage: payload.fidelityClientLimitPerPage,
});

const changeCountFidelityClient: ClientReducers<
  ReturnType<typeof ClientActions.changeCountFidelityClient>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  countFidelityClient: payload.countFidelityClient,
});

const changeOrderHistoryClientPage: ClientReducers<
  ReturnType<typeof ClientActions.changeOrderHistoryClientPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  orderHistoryClientPage: payload.orderHistoryClientPage,
});

const changeOrderHistoryClientLimitPerPage: ClientReducers<
  ReturnType<typeof ClientActions.changeOrderHistoryClientLimitPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  orderHistoryClientLimitPerPage: payload.orderHistoryClientLimitPerPage,
});

const changeCountOrderHistoryClient: ClientReducers<
  ReturnType<typeof ClientActions.changeCountOrderHistoryClient>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  countOrderHistoryClient: payload.countOrderHistoryClient,
});

const reducer = createReducer<ClientState>(INITIAL_STATE)
  .handleAction(ClientTypes.SELECTED_CLIENT, setSelectedClient)
  .handleAction(ClientTypes.SELECTED_ORDER_HISTORY, setSelectedOrderHistory)
  .handleAction(ClientTypes.SET_VIEW_TYPE_CLIENT, setViewTypeClient)
  .handleAction(ClientTypes.CHANGE_TAB_INDEX, changeTabIndex)
  .handleAction(
    ClientTypes.SET_FIDELITY_CLIENT_SELECTED,
    setFidelityClientSelected,
  )
  .handleAction(ClientTypes.CHANGE_CLIENT_PAGE, changeClientPage)
  .handleAction(
    ClientTypes.CHANGE_CLIENT_LIMIT_PER_PAGE,
    changeClientLimitPerPage,
  )
  .handleAction(ClientTypes.CHANGE_COUNT_CLIENT, changeCountClient)
  .handleAction(
    ClientTypes.CHANGE_FIDELITY_CLIENT_PAGE,
    changeFidelityClientPage,
  )
  .handleAction(
    ClientTypes.CHANGE_FIDELITY_CLIENT_LIMIT_PER_PAGE,
    changeFidelityClientLimitPerPage,
  )
  .handleAction(
    ClientTypes.CHANGE_COUNT_FIDELITY_CLIENT,
    changeCountFidelityClient,
  )
  .handleAction(
    ClientTypes.CHANGE_ORDER_HISTORY_CLIENT_PAGE,
    changeOrderHistoryClientPage,
  )
  .handleAction(
    ClientTypes.CHANGE_ORDER_HISTORY_CLIENT_LIMIT_PER_PAGE,
    changeOrderHistoryClientLimitPerPage,
  )
  .handleAction(
    ClientTypes.CHANGE_COUNT_ORDER_HISTORY_CLIENT,
    changeCountOrderHistoryClient,
  );

export default reducer;
