import { OrderDTO } from '../../../dtos/auth/OrdersDTO';
import { ClientFidelityProps } from '../../../dtos/auth/FidelityDTO';

export enum ClientTypes {
  SELECTED_CLIENT = '@clients/SELECTED_CLIENT',
  SELECTED_ORDER_HISTORY = '@clients/SELECTED_ORDER_HISTORY',
  SET_VIEW_TYPE_CLIENT = '@clients/SET_VIEW_TYPE_CLIENT',
  SET_FIDELITY_CLIENT_SELECTED = '@clients/SET_FIDELITY_CLIENT_SELECTED',
  CHANGE_TAB_INDEX = '@clients/CHANGE_TAB_INDEX',
  CHANGE_CLIENT_PAGE = '@clients/CHANGE_CLIENT_PAGE',
  CHANGE_CLIENT_LIMIT_PER_PAGE = '@clients/CHANGE_CLIENT_LIMIT_PER_PAGE',
  CHANGE_COUNT_CLIENT = '@clients/CHANGE_COUNT_CLIENT',
  CHANGE_FIDELITY_CLIENT_PAGE = '@clients/CHANGE_FIDELITY_CLIENT_PAGE',
  CHANGE_FIDELITY_CLIENT_LIMIT_PER_PAGE = '@clients/CHANGE_FIDELITY_CLIENT_LIMIT_PER_PAGE',
  CHANGE_COUNT_FIDELITY_CLIENT = '@clients/CHANGE_COUNT_FIDELITY_CLIENT',
  CHANGE_ORDER_HISTORY_CLIENT_PAGE = '@clients/CHANGE_ORDER_HISTORY_CLIENT_PAGE',
  CHANGE_ORDER_HISTORY_CLIENT_LIMIT_PER_PAGE = '@clients/CHANGE_ORDER_HISTORY_CLIENT_LIMIT_PER_PAGE',
  CHANGE_COUNT_ORDER_HISTORY_CLIENT = '@clients/CHANGE_COUNT_ORDER_HISTORY_CLIENT',
}

export interface ClientState {
  readonly selectedClientId?: string | null;
  readonly selectedOrderHistory?: OrderDTO | null;
  readonly viewTypeClient: 'create' | 'view' | 'edit'; // create | view | edit
  readonly tabIndex: number;
  readonly fidelityClientSelected: ClientFidelityProps | null;
  readonly clientPage: number;
  readonly clientLimitPerPage: number;
  readonly countClient: number;
  readonly fidelityClientPage: number;
  readonly fidelityClientLimitPerPage: number;
  readonly countFidelityClient: number;
  readonly orderHistoryClientPage: number;
  readonly orderHistoryClientLimitPerPage: number;
  readonly countOrderHistoryClient: number;
}
