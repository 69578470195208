import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';

import * as CampaignActions from './actions';
import { CampaignTypes, CampaignsState } from './types';

const INITIAL_STATE: CampaignsState = {
  selectedCampaignId: null,
  formTypeCampaign: 'view',
  page: 1,
  limitPerPage: 10,
  count: 0,
};

type CampaignReducers<Action extends AnyAction> = Reducer<
  CampaignsState,
  Action
>;

const setSelectedCampaignId: CampaignReducers<
  ReturnType<typeof CampaignActions.setSelectedCampaignId>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedCampaignId: payload.selectedCampaignId,
});

const setViewTypeCampaign: CampaignReducers<
  ReturnType<typeof CampaignActions.setViewTypeCampaign>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  formTypeCampaign: payload.formTypeCampaign,
});

const changePage: CampaignReducers<
  ReturnType<typeof CampaignActions.changePage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  page: payload.page,
});

const changeLimitPerPage: CampaignReducers<
  ReturnType<typeof CampaignActions.changeLimitPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  limitPerPage: payload.limitPerPage,
});

const changeCount: CampaignReducers<
  ReturnType<typeof CampaignActions.changeCount>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  count: payload.count,
});

const reducer = createReducer<CampaignsState>(INITIAL_STATE)
  .handleAction(CampaignTypes.SELECTED_CAMPAIGN_ID, setSelectedCampaignId)
  .handleAction(CampaignTypes.SET_VIEW_TYPE_CAMPAIGN, setViewTypeCampaign)
  .handleAction(CampaignTypes.CHANGE_PAGE, changePage)
  .handleAction(CampaignTypes.CHANGE_LIMIT_PER_PAGE, changeLimitPerPage)
  .handleAction(CampaignTypes.CHANGE_COUNT, changeCount);

export default reducer;
