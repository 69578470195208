import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';

import * as AdvertisementActions from './actions';
import { AdvertisementTypes, AdvertisementState } from './types';

const INITIAL_STATE: AdvertisementState = {
  selectedAdvertisementId: null,
  viewTypeAdvertisement: 'create',
  page: 1,
  limitPerPage: 10,
  count: 0,
};

type AdvertisementReducers<Action extends AnyAction> = Reducer<
  AdvertisementState,
  Action
>;

const setSelectedAdvertisementId: AdvertisementReducers<
  ReturnType<typeof AdvertisementActions.setSelectedAdvertisementId>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedAdvertisementId: payload.advertisement_id,
});

const setViewTypeAdvertisement: AdvertisementReducers<
  ReturnType<typeof AdvertisementActions.setViewTypeAdvertisement>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  viewTypeAdvertisement: payload.viewTypeAdvertisement,
});

const changePage: AdvertisementReducers<
  ReturnType<typeof AdvertisementActions.changePage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  page: payload.page,
});

const changeLimitPerPage: AdvertisementReducers<
  ReturnType<typeof AdvertisementActions.changeLimitPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  limitPerPage: payload.limitPerPage,
});

const changeCount: AdvertisementReducers<
  ReturnType<typeof AdvertisementActions.changeCount>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  count: payload.count,
});

const reducer = createReducer<AdvertisementState>(INITIAL_STATE)
  .handleAction(
    AdvertisementTypes.SELECTED_ADVERTISEMENT_ID,
    setSelectedAdvertisementId,
  )
  .handleAction(
    AdvertisementTypes.SET_VIEW_TYPE_ADVERTISEMENT,
    setViewTypeAdvertisement,
  )
  .handleAction(AdvertisementTypes.CHANGE_PAGE, changePage)
  .handleAction(AdvertisementTypes.CHANGE_LIMIT_PER_PAGE, changeLimitPerPage)
  .handleAction(AdvertisementTypes.CHANGE_COUNT, changeCount);

export default reducer;
