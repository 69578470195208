import React from 'react';
import { useSelector } from 'react-redux';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';

import { ApplicationState } from '../store/types';

interface RouteProps extends ReactDOMRouteProps {
  isPublic?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPublic = false,
  component: Component,
  ...rest
}) => {
  const { token } = useSelector((store: ApplicationState) => store.auth);

  const userIsSigned = !!token;

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return !isPublic === !!userIsSigned ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: isPublic ? '/dashboard' : '/',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

Route.defaultProps = {
  isPublic: false,
};

export default Route;
