import { BranchDTO } from '../../../dtos/auth/BrachesDTO';

export enum BranchTypes {
  SELECTED_BRANCH = '@notification/SELECTED_NOTIFICATION',
  CHANGE_PAGE = '@branches/CHANGE_PAGE',
  CHANGE_LIMIT_PER_PAGE = '@branches/CHANGE_LIMIT_PER_PAGE',
  CHANGE_COUNT = '@branches/CHANGE_COUNT',
}

export interface BranchState {
  readonly selectedBranch: BranchDTO | null;
  readonly viewType: string | null;
  readonly page: number;
  readonly limitPerPage: number;
  readonly count: number;
}
