import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../store/types';

import { loadProfileData } from '../store/ducks/user/actions';

import PublicRoutes from './public.routes';
import AdminRoutes from './admin.routes';

const Routes: React.FC = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((store: ApplicationState) => store.auth);

  const userIsSigned = !!token;
  useEffect(() => {
    if (userIsSigned) {
      dispatch(loadProfileData());
    }
  }, [token]);

  return userIsSigned ? <AdminRoutes /> : <PublicRoutes />;
};

export default Routes;
