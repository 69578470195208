export enum PaymentsMethodsTypes {
  SET_PAYMENT_METHOD_ID_SELECTED = '@paymentsMethods/SET_PAYMENT_METHOD_ID_SELECTED',
  SET_VIEW_TYPE_PAYMENT_METHOD = '@paymentsMethods/SET_VIEW_TYPE_PAYMENT_METHOD',
  CHANGE_PAYMENTS_METHODS_PAGE = '@paymentsMethods/CHANGE_PAYMENTS_METHODS_PAGE',
  CHANGE_PAYMENTS_METHODS_LIMIT_PER_PAGE = '@paymentsMethods/CHANGE_PAYMENTS_METHODS_LIMIT_PER_PAGE',
  CHANGE_COUNT_PAYMENTS_METHODS = '@paymentsMethods/CHANGE_COUNT_PAYMENTS_METHODS',
}

export interface PaymentsMethodsState {
  readonly paymentMethodIdSelected: string | null;
  readonly viewTypePaymentMethod: 'view' | 'edit' | 'create';
  readonly paymentsMethodsPage: number;
  readonly paymentsMethodsLimitPerPage: number;
  readonly countPaymentsMethods: number;
}
