export enum MessageContactTypes {
  SELECTED_MESSAGE_CONTACT_ID = '@contact/SELECTED_MESSAGE_CONTACT_ID',
  SET_VIEW_TYPE_MESSAGE_CONTACT = '@contact/SET_VIEW_TYPE_MESSAGE_CONTACT',
  CHANGE_CONTACT_US_PAGE = '@faq/CHANGE_CONTACT_US_PAGE',
  CHANGE_CONTACT_US_LIMIT_PER_PAGE = '@faq/CHANGE_CONTACT_US_LIMIT_PER_PAGE',
  CHANGE_COUNT_CONTACT_US = '@faq/CHANGE_COUNT_CONTACT_US',
}

export interface MessageContactState {
  readonly selectedMessageContactId?: string | null;
  readonly viewTypeMessageContact: string; // view | edit | answer
  readonly contactUsPage: number;
  readonly contactUsLimitPerPage: number;
  readonly countContactUs: number;
}
