import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';

import * as UserActions from './actions';
import { UserTypes, UserState } from './types';

const INITIAL_STATE: UserState = {
  profileData: null,
};

type UserReducers<Action extends AnyAction> = Reducer<UserState, Action>;

const setProfileData: UserReducers<
  ReturnType<typeof UserActions.setProfileData>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  profileData: payload.profileData,
});

const reducer = createReducer<UserState>(INITIAL_STATE).handleAction(
  UserTypes.SET_PROFILE_DATA,
  setProfileData,
);

export default reducer;
