import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

interface MenuItemProps {
  open?: boolean;
  $isActiveItem?: boolean;
}
export const Container = styled.div<{ isSidebarOpen?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: ${(props) => (props.isSidebarOpen ? '200px' : '68px')};
  background-color: ${(props) => props.theme.colors.lightestGrey};
  /* transition: min-width 0.2s ease; */
  height: 100%;

  button {
    display: flex;
    align-items: center;
    justify-content: ${(props) =>
      props.isSidebarOpen ? 'flex-end' : 'center'};
    padding: 10px 0;
    width: 100%;
    z-index: 1;
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey};
    margin-top: 10px;

    @media screen and (max-height: 800px) {
      padding: 5px 0;
      margin-top: 5px;
    }

    svg {
      width: 18px;
      height: 18px !important;
      object-fit: fill;
      color: ${(props) => props.theme.colors.darkerGrey};
      transition: ease-in-out;

      ${(props) =>
        !props.isSidebarOpen &&
        css`
          transform: rotate(180deg);
        `};
    }

    &:hover {
      > svg {
        color: ${(props) => props.theme.colors.darkGrey};
      }
    }
  }

  img {
    margin-top: 32px;
    /* width: ${(props) => (props.isSidebarOpen ? '92px' : '36px')}; */
    height: 50px;
    margin-bottom: 40px;

    @media screen and (max-height: 800px) {
      margin-top: 16px;
      height: 50px;
      margin-bottom: 20px;
    }
  }

  P {
    font-size: 16px;
    margin-top: 16px;
  }
`;

export const MenuItems = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;
  height: 100%;
`;

export const MenuItem = styled(Link)<MenuItemProps>`
  text-decoration: none;
  color: ${(props) => props.theme.colors.darkerGrey};
  @media screen and (max-height: 800px) {
    height: 32px !important;
  }

  li {
    display: flex;
    min-width: 100%;
    align-items: center;
    font-size: 18px;
    line-height: 44px;
    min-height: 44px;
    color: ${(props) => props.$isActiveItem && props.theme.colors.white};
    background-color: ${(props) =>
      props.$isActiveItem && props.theme.colors.primary};

    @media screen and (max-height: 800px) {
      height: 32px !important;
      min-height: 32px !important;
    }

    ${(props) =>
      !props.open &&
      css`
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 68px;
      `}
    svg {
      width: 42px;
      fill: ${(props) =>
        props.$isActiveItem
          ? props.theme.colors.white
          : props.theme.colors.darkerGrey};
    }

    svg#icon-svg-notification {
      fill: none;
      stroke: ${(props) =>
        props.$isActiveItem
          ? props.theme.colors.white
          : props.theme.colors.darkerGrey};
    }
    &:hover {
      justify-content: ${(props) => !props.open && 'flex-start'};
      ::before {
        content: '';
        width: 10px;
        height: 44px;
        background-color: ${(props) => props.theme.colors.primary};
        transition: all 0.2s ease-in-out;

        @media screen and (max-height: 800px) {
          height: 32px !important;
        }
      }

      color: ${(props) =>
        props.$isActiveItem
          ? props.theme.colors.white
          : props.theme.colors.primary};

      svg {
        margin-left: 2px;
        fill: ${(props) => !props.$isActiveItem && props.theme.colors.primary};
      }

      svg#icon-svg-notification {
        fill: none;
        stroke: ${(props) =>
          !props.$isActiveItem && props.theme.colors.primary};
      }
    }
  }
`;
