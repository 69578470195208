import IO from 'socket.io-client';

export const appSocket = IO(
  process.env.REACT_APP_BASEURL ? process.env.REACT_APP_BASEURL : '',
  {
    transports: ['websocket'],
    reconnection: true,
    reconnectionDelay: 2000,
    reconnectionDelayMax: 10000,
    reconnectionAttempts: 3,
    withCredentials: true,
    secure: true,
    auth: {
      token: `Bearer ${localStorage.getItem('token')}`,
    },
  },
);
