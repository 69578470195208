import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';

import * as ProductActions from './actions';
import { ProductTypes, ProductState } from './types';

const INITIAL_STATE: ProductState = {
  selectedProduct: null,
  viewTypeProduct: 'view',
  tabIndex: 1,
  productPage: 1,
  productLimitPerPage: 10,
  countProduct: 0,
};

type ProductReducers<Action extends AnyAction> = Reducer<ProductState, Action>;

const setSelectedProduct: ProductReducers<
  ReturnType<typeof ProductActions.setSelectedProduct>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedProduct: payload.dataProduct,
});

const setViewTypeProduct: ProductReducers<
  ReturnType<typeof ProductActions.setViewTypeProduct>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  viewTypeProduct: payload.viewTypeProduct,
});

const changeTabIndex: ProductReducers<
  ReturnType<typeof ProductActions.changeTabIndex>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  tabIndex: payload.tabIndex,
});

const changeProductPage: ProductReducers<
  ReturnType<typeof ProductActions.changeProductPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  productPage: payload.productPage,
});

const changeProductLimitPerPage: ProductReducers<
  ReturnType<typeof ProductActions.changeProductLimitPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  productLimitPerPage: payload.productLimitPerPage,
});

const changeCountProduct: ProductReducers<
  ReturnType<typeof ProductActions.changeCountProduct>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  countProduct: payload.countProduct,
});

const reducer = createReducer<ProductState>(INITIAL_STATE)
  .handleAction(ProductTypes.SELECTED_PRODUCT, setSelectedProduct)
  .handleAction(ProductTypes.SET_VIEW_TYPE_PRODUCT, setViewTypeProduct)
  .handleAction(ProductTypes.CHANGE_TAB_INDEX, changeTabIndex)
  .handleAction(ProductTypes.CHANGE_PRODUCT_PAGE, changeProductPage)
  .handleAction(
    ProductTypes.CHANGE_PRODUCT_LIMIT_PER_PAGE,
    changeProductLimitPerPage,
  )
  .handleAction(ProductTypes.CHANGE_COUNT_PRODUCT, changeCountProduct);

export default reducer;
