import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';

import * as FeesActions from './actions';
import { FeesTypes, FeesState } from './types';

const INITIAL_STATE: FeesState = {
  feesIdSelected: null,
  viewTypeFees: 'create',
  feesPage: 1,
  feesLimitPerPage: 10,
  countFees: 0,
};

type FeesReducers<Action extends AnyAction> = Reducer<FeesState, Action>;

const setFeesIdSelected: FeesReducers<
  ReturnType<typeof FeesActions.setFeesIdSelected>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  feesIdSelected: payload.feesIdSelected,
});

const setViewTypeFees: FeesReducers<
  ReturnType<typeof FeesActions.setViewTypeFees>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  viewTypeFees: payload.viewTypeFees,
});

const changeFeesPage: FeesReducers<
  ReturnType<typeof FeesActions.changeFeesPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  feesPage: payload.feesPage,
});

const changeFeesLimitPerPage: FeesReducers<
  ReturnType<typeof FeesActions.changeFeesLimitPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  feesLimitPerPage: payload.feesLimitPerPage,
});

const changeCountFees: FeesReducers<
  ReturnType<typeof FeesActions.changeCountFees>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  countFees: payload.countFees,
});

const reducer = createReducer<FeesState>(INITIAL_STATE)
  .handleAction(FeesTypes.SET_FEES_ID_SELECTED, setFeesIdSelected)
  .handleAction(FeesTypes.SET_VIEW_TYPE_FEES, setViewTypeFees)
  .handleAction(FeesTypes.CHANGE_FEES_PAGE, changeFeesPage)
  .handleAction(FeesTypes.CHANGE_FEES_LIMIT_PER_PAGE, changeFeesLimitPerPage)
  .handleAction(FeesTypes.CHANGE_COUNT_FEES, changeCountFees);

export default reducer;
