export enum CampaignTypes {
  SELECTED_CAMPAIGN_ID = '@campaigns/SELECTED_CAMPAIGN_ID',
  SET_VIEW_TYPE_CAMPAIGN = '@campaigns/SET_VIEW_TYPE_CAMPAIGN',
  CHANGE_PAGE = '@campaigns/CHANGE_PAGE',
  CHANGE_LIMIT_PER_PAGE = '@campaigns/CHANGE_LIMIT_PER_PAGE',
  CHANGE_COUNT = '@campaigns/CHANGE_COUNT',
}

export interface CampaignsState {
  readonly selectedCampaignId: string | null;
  readonly formTypeCampaign: 'view' | 'edit' | 'create';
  readonly page: number;
  readonly limitPerPage: number;
  readonly count: number;
}
