import { action } from 'typesafe-actions';
import { OrderTypes } from './types';
import { FormikOrderProductDTO, OrderDTO } from '../../../dtos/auth/OrdersDTO';

export const setSelectedOrder = (
  data: OrderDTO | null,
  viewType: string | null,
  indexTab?: number | null,
) => action(OrderTypes.SELECTED_ORDER, { data, viewType, indexTab });

export const setProductsOrder = (data: FormikOrderProductDTO[] | null) =>
  action(OrderTypes.PRODUCTS_ORDER, { data });

export const changeFixePin = (pinFixed: boolean) =>
  action(OrderTypes.PIN_FIXED, { pinFixed });

export const changePage = (page: number) =>
  action(OrderTypes.CHANGE_PAGE, { page });

export const changeLimitPerPage = (limitPerPage: number) =>
  action(OrderTypes.CHANGE_LIMIT_PER_PAGE, { limitPerPage });

export const changeCount = (count: number) =>
  action(OrderTypes.CHANGE_COUNT, { count });

export const changeTab = (tab: number) =>
  action(OrderTypes.CHANGE_TAB, { tab });
