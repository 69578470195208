import { PlanDTO } from '../../../dtos/auth/PlansDTO';

export enum PlanTypes {
  SELECTED_PLAN = '@auth/SELECTED_PLAN',
  CHANGE_VIEW_TYPE_PLAN = '@plans/CHANGE_VIEW_TYPE_PLAN',
}

export interface PlanState {
  readonly selectedPlan?: PlanDTO | null;
  readonly viewTypePlan?: string | null; // create | view | edit
}
