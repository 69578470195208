import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';

import * as PaymentsMethodsActions from './actions';
import { PaymentsMethodsTypes, PaymentsMethodsState } from './types';

const INITIAL_STATE: PaymentsMethodsState = {
  paymentMethodIdSelected: null,
  viewTypePaymentMethod: 'create',
  paymentsMethodsPage: 1,
  paymentsMethodsLimitPerPage: 10,
  countPaymentsMethods: 0,
};

type PaymentsMethodsReducers<Action extends AnyAction> = Reducer<
  PaymentsMethodsState,
  Action
>;

const setPaymentMethodIdSelected: PaymentsMethodsReducers<
  ReturnType<typeof PaymentsMethodsActions.setPaymentMethodIdSelected>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  paymentMethodIdSelected: payload.paymentMethodIdSelected,
});

const setViewTypePaymentMethod: PaymentsMethodsReducers<
  ReturnType<typeof PaymentsMethodsActions.setViewTypePaymentMethod>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  viewTypePaymentMethod: payload.viewTypePaymentMethod,
});

const changePaymentsMethodsPage: PaymentsMethodsReducers<
  ReturnType<typeof PaymentsMethodsActions.changePaymentsMethodsPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  paymentsMethodsPage: payload.paymentsMethodsPage,
});

const changePaymentsMethodsLimitPerPage: PaymentsMethodsReducers<
  ReturnType<typeof PaymentsMethodsActions.changePaymentsMethodsLimitPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  paymentsMethodsLimitPerPage: payload.paymentsMethodsLimitPerPage,
});

const changeCountPaymentsMethods: PaymentsMethodsReducers<
  ReturnType<typeof PaymentsMethodsActions.changeCountPaymentsMethods>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  countPaymentsMethods: payload.countPaymentsMethods,
});

const reducer = createReducer<PaymentsMethodsState>(INITIAL_STATE)
  .handleAction(
    PaymentsMethodsTypes.SET_PAYMENT_METHOD_ID_SELECTED,
    setPaymentMethodIdSelected,
  )
  .handleAction(
    PaymentsMethodsTypes.SET_VIEW_TYPE_PAYMENT_METHOD,
    setViewTypePaymentMethod,
  )
  .handleAction(
    PaymentsMethodsTypes.CHANGE_PAYMENTS_METHODS_PAGE,
    changePaymentsMethodsPage,
  )
  .handleAction(
    PaymentsMethodsTypes.CHANGE_PAYMENTS_METHODS_LIMIT_PER_PAGE,
    changePaymentsMethodsLimitPerPage,
  )
  .handleAction(
    PaymentsMethodsTypes.CHANGE_COUNT_PAYMENTS_METHODS,
    changeCountPaymentsMethods,
  );

export default reducer;
