import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';
import * as NotificationActions from './actions';

import { NotificationState, NotificationTypes } from './types';

const INITIAL_STATE: NotificationState = {
  selectedNotification: null,
  viewType: null,
  pageNotification: 1,
  limitPerPageNotification: 10,
  countNotification: 0,
};

type NotificationReducers<Action extends AnyAction> = Reducer<
  NotificationState,
  Action
>;

const setSelectedNotification: NotificationReducers<
  ReturnType<typeof NotificationActions.setSelectedNotification>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedNotification: payload.data,
  viewType: payload.viewType,
});

const changePageNotification: NotificationReducers<
  ReturnType<typeof NotificationActions.changePageNotification>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  pageNotification: payload.pageNotification,
});

const changeLimitPerPageNotification: NotificationReducers<
  ReturnType<typeof NotificationActions.changeLimitPerPageNotification>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  limitPerPageNotification: payload.limitPerPageNotification,
});

const changeCountNotification: NotificationReducers<
  ReturnType<typeof NotificationActions.changeCountNotification>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  countNotification: payload.countNotification,
});

const reducer = createReducer<NotificationState>(INITIAL_STATE)
  .handleAction(
    NotificationTypes.SELECTED_NOTIFICATION,
    setSelectedNotification,
  )
  .handleAction(
    NotificationTypes.CHANGE_PAGE_NOTIFICATION,
    changePageNotification,
  )
  .handleAction(
    NotificationTypes.CHANGE_LIMIT_PER_PAGE_NOTIFICATION,
    changeLimitPerPageNotification,
  )
  .handleAction(
    NotificationTypes.CHANGE_COUNT_NOTIFICATION,
    changeCountNotification,
  );

export default reducer;
