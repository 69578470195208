import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { all, takeLatest, call, put, StrictEffect } from 'redux-saga/effects';

import { UserAPI } from '../../../libs/api/user';

import { UserTypes } from './types';
import { setProfileData } from './actions';

import { UserDTO } from '../../../dtos/auth/UserDTO';

function* loadProfileData(): Generator<StrictEffect, any, UserDTO> {
  try {
    const data = yield call(UserAPI.getProfileData);

    yield put(setProfileData(data));
  } catch (err) {
    let messageErro = '';
    const error = err as Error | AxiosError;
    if (axios.isAxiosError(error)) {
      if (error.response) {
        messageErro = error.response.data.message;
      } else {
        messageErro = 'Não foi possível carregar os dados do perfil!';
      }
    } else {
      messageErro = 'Ocorreu um problema ao carregar os dados do perfil!';
    }
    toast.error(messageErro);
  }
}

export default all([takeLatest(UserTypes.LOAD_PROFILE_DATA, loadProfileData)]);
