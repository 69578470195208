export enum AdvertisementTypes {
  SELECTED_ADVERTISEMENT_ID = '@advertisement/SELECTED_ADVERTISEMENT_ID',
  SET_VIEW_TYPE_ADVERTISEMENT = '@advertisement/SET_VIEW_TYPE_ADVERTISEMENT',
  CHANGE_PAGE = '@advertisement/CHANGE_PAGE',
  CHANGE_LIMIT_PER_PAGE = '@advertisement/CHANGE_LIMIT_PER_PAGE',
  CHANGE_COUNT = '@advertisement/CHANGE_COUNT',
}

export interface AdvertisementState {
  readonly selectedAdvertisementId: string | null;
  readonly viewTypeAdvertisement: 'view' | 'edit' | 'create' | null;
  readonly page: number;
  readonly limitPerPage: number;
  readonly count: number;
}
