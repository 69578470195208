import React, { lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PublicLayout from '../components/Layouts/PublicLayout';

import Route from './Route';

const publicRoutes = [
  {
    path: '/',
    component: lazy(() => import('../pages/Public/Login')),
    exact: true,
  },
  {
    path: '/recover-password',
    component: lazy(() => import('../pages/Public/RecoverPassword')),
    exact: true,
  },
];

const PublicRoutes: React.FC = () => {
  return (
    <PublicLayout>
      <Switch>
        {publicRoutes.map((route, id) => {
          return <Route key={String(id)} {...route} isPublic />;
        })}
        <Route path="*" component={() => <Redirect to="/" />} />
      </Switch>
    </PublicLayout>
  );
};

export default PublicRoutes;
