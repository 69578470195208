import React from 'react';
import { useTheme } from 'styled-components';
import Modal from 'react-modal';
import { RiCloseCircleFill } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import Loading from '../Loading';

import { Container } from './styles';
import { OrderDTO } from '../../dtos/auth/OrdersDTO';
import GeneralButton from '../Buttons/GeneralButton';

import * as masks from '../../utils/masks';

interface ModalImageProps {
  isOpen: boolean;
  orders: OrderDTO[];
  loading?: boolean;
  requestClose: () => void;
  scheduled?: boolean;
}

export const AlertOrderModal: React.FC<ModalImageProps> = ({
  isOpen = false,
  orders = [],
  loading = false,
  requestClose,
  scheduled = false,
}) => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <Container>
      <Modal
        isOpen={isOpen}
        // onRequestClose={requestClose}
        overlayClassName="overlay-products-combo"
        shouldCloseOnEsc
        style={{
          content: {
            display: 'flex',
            width: '400px',
            minHeight: '200px',
            maxHeight: '400px',
            height: 'fit-content',
            margin: 'auto',
            overflow: 'hidden',
            padding: '10px 30px',
            borderRadius: '20px',
            justifyContent: 'center',
            zIndex: 9999,
          },
        }}
      >
        <span
          style={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            backgroundColor: scheduled
              ? theme.colors.lightYellow
              : theme.colors.green,
            width: '100%',
            height: '60px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: '22px',
            paddingLeft: '15px',
          }}
        >
          {scheduled
            ? 'PEDIDOS AGENDADOS'
            : `NOVOS PEDIDOS RECEBIDOS - (${orders.length})`}
          <RiCloseCircleFill
            size={48}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              zIndex: 2,
            }}
            color={theme.colors.white}
            onClick={requestClose}
            cursor="pointer"
          />
        </span>

        <button
          type="button"
          style={{
            width: '95%',
            maxHeight: '100%',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '60px 0 0 0',
            fontSize: '18px',
          }}
          onClick={() => {
            history.push('/pedidos');
            requestClose();
          }}
        >
          {scheduled && 'Atenção!'}
          {orders.map((order) => (
            <div
              key={order.id}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                maxHeight: '100%',
                overflowX: 'hidden',
                overflowY: 'auto',
                marginBottom: '5px',
              }}
            >
              {scheduled ? (
                <>
                  <span>{`Pedido #${order.code}`}</span>
                  <span>
                    {`agendado para entregar as ${
                      (order?.schedule_time &&
                        masks.formatTime(order?.schedule_time)) ||
                      '--:--'
                    }`}
                  </span>
                </>
              ) : (
                <>
                  <span>{`Pedido #${order.code}`}</span>
                  <span>
                    {(order?.created_at &&
                      masks.dateTimeMask(order?.created_at)) ||
                      '--/--/---- --:--'}
                  </span>
                </>
              )}
            </div>
          ))}
        </button>
        {/* <div
          style={{
            position: 'absolute',
            bottom: '0px',
            left: '0px',
            width: '100%',
            height: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <GeneralButton
            onClick={() => {
              // AlertSound.pause();
              requestClose();
            }}
            label="Confirmar"
            typeLayoutButton="save"
            style={{ margin: 0 }}
          />
        </div> */}
      </Modal>
    </Container>
  );
};
