import { ContributorDTO } from '../../../dtos/auth/ContributorsDTO';

export enum ContributorTypes {
  SELECTED_CONTRIBUTOR = '@contributors/SELECTED_CONTRIBUTOR',
  CHANGE_PAGE = '@contributors/CHANGE_PAGE',
  CHANGE_LIMIT_PER_PAGE = '@contributors/CHANGE_LIMIT_PER_PAGE',
  CHANGE_COUNT = '@contributors/CHANGE_COUNT',
}

export interface ContributorSatate {
  readonly selectedContributor?: ContributorDTO | null;
  readonly viewType?: string | null;
  readonly page: number;
  readonly limitPerPage: number;
  readonly count: number;
}
