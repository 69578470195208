export enum FeesTypes {
  SET_FEES_ID_SELECTED = '@fees/SET_FEES_ID_SELECTED',
  SET_VIEW_TYPE_FEES = '@fees/SET_VIEW_TYPE_FEES',
  CHANGE_FEES_PAGE = '@fees/CHANGE_FEES_PAGE',
  CHANGE_FEES_LIMIT_PER_PAGE = '@fees/CHANGE_FEES_LIMIT_PER_PAGE',
  CHANGE_COUNT_FEES = '@fees/CHANGE_COUNT_FEES',
}

export interface FeesState {
  readonly feesIdSelected: string | null;
  readonly viewTypeFees: 'view' | 'edit' | 'create';
  readonly feesPage: number;
  readonly feesLimitPerPage: number;
  readonly countFees: number;
}
