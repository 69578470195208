import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';

import * as FAQActions from './actions';
import { FAQTypes, FAQState } from './types';

const INITIAL_STATE: FAQState = {
  selectedFAQ: null,
  viewTypeFaq: 'register',
  faqPage: 1,
  faqLimitPerPage: 10,
  countFaq: 0,
};

type FAQReducers<Action extends AnyAction> = Reducer<FAQState, Action>;

const setSelectedFAQ: FAQReducers<
  ReturnType<typeof FAQActions.setSelectedFAQ>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedFAQ: payload.dataFAQ,
});

const setViewTypeFAQ: FAQReducers<
  ReturnType<typeof FAQActions.setViewTypeFAQ>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  viewTypeFaq: payload.viewTypeFaq,
});

const changeFaqPage: FAQReducers<
  ReturnType<typeof FAQActions.changeFaqPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  faqPage: payload.faqPage,
});

const changeFaqLimitPerPage: FAQReducers<
  ReturnType<typeof FAQActions.changeFaqLimitPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  faqLimitPerPage: payload.faqLimitPerPage,
});

const changeCountFaq: FAQReducers<
  ReturnType<typeof FAQActions.changeCountFaq>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  countFaq: payload.countFaq,
});

const reducer = createReducer<FAQState>(INITIAL_STATE)
  .handleAction(FAQTypes.SELECTED_FAQ, setSelectedFAQ)
  .handleAction(FAQTypes.SELECTED_VIEW_TYPE_FAQ, setViewTypeFAQ)
  .handleAction(FAQTypes.CHANGE_FAQ_PAGE, changeFaqPage)
  .handleAction(FAQTypes.CHANGE_FAQ_LIMIT_PER_PAGE, changeFaqLimitPerPage)
  .handleAction(FAQTypes.CHANGE_COUNT_FAQ, changeCountFaq);

export default reducer;
