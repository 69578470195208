import api from '../../services/api';
import {
  UserDTO,
  EditProfileProps,
  AddressUserProps,
} from '../../dtos/auth/UserDTO';

async function getProfileData(): Promise<UserDTO> {
  const { data } = await api.get<UserDTO>(`/profile`);

  return data;
}

async function editProfilePersonalData(
  data: EditProfileProps,
): Promise<number> {
  const { status } = await api.put('/profile', data);

  return status;
}

interface EditProfilePicture {
  status: number;
  avatar: string;
}

async function editProfilePicture(
  dataProfilePicture: FormData,
): Promise<EditProfilePicture> {
  const { status, data } = await api.patch<{ avatar: string }>(
    '/profile/avatar',
    dataProfilePicture,
  );

  return { status, avatar: data.avatar };
}

interface EditProfileAddress {
  status: number;
  data: AddressUserProps;
}

async function editProfileAddress(
  dataAddress: AddressUserProps,
): Promise<EditProfileAddress> {
  const { data, status } = await api.put<AddressUserProps>(
    `/profile/addresses/${dataAddress.id}`,
    dataAddress,
  );

  return { status, data };
}

export const UserAPI = {
  getProfileData,
  editProfilePersonalData,
  editProfilePicture,
  editProfileAddress,
};
