import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Container, Content } from './styles';

import Siderbar from '../../Siderbar';
import { appSocket } from '../../../services/appSocket';
import {
  MessageBranchProps,
  MessageDataProps,
  OrderDTO,
} from '../../../dtos/auth/OrdersDTO';
import NewOrder from '../../../assets/sounds/AlertSound.mp3';
import NewMessage from '../../../assets/sounds/NewMessage.mp3';
import { currentOS } from '../../../utils/defaults';
import { AlertOrderModal } from '../../AlertOrderModal';
import { ApplicationState } from '../../../store/types';
import theme from '../../../styles/theme';
import { setSelectedOrder } from '../../../store/ducks/orders/actions';
import store from '../../../store/index';

export const AlertSound = new Audio(NewOrder);
AlertSound.pause();
AlertSound.autoplay = true;

export const MessageAlertSound = new Audio(NewMessage);
MessageAlertSound.pause();
MessageAlertSound.autoplay = true;

const AdminLayout: React.FC = ({ children }) => {
  const LocalPath = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [openModalNew, setOpenModalNew] = useState(false);
  const [newOrders, setNewOrders] = useState<OrderDTO[]>([]);
  const [scheduledOrders, setScheduledOrders] = useState<OrderDTO[]>([]);
  const [path, setPath] = useState<string>(LocalPath.pathname);
  const [scheduled, setScheduled] = useState(false);
  const token = useSelector(
    (state: ApplicationState) => state?.auth?.data?.token,
  );
  const [authorized, setAuthorized] = useState(true);

  useEffect(() => {
    // const state = store.getState() as ApplicationState;
    // const { data } = state.auth;

    if (!authorized) {
      appSocket.disconnect();
      setAuthorized(true);
    }

    appSocket.auth = { token: `Bearer ${token}` };
    appSocket.connect();
  }, [token, !authorized]);

  useEffect(() => {
    appSocket.on('connect', () => {
      setAuthorized(true);
      console.log('Socket connected');
    });

    appSocket.on('error', (data) => {
      console.error('socket error', data);
      if (data.message === 'Token inválido') {
        setAuthorized(false);
      }
    });

    appSocket.emit('subscribe_to_orders', {});

    appSocket.on('new_order', async (order: OrderDTO) => {
      if (
        order &&
        !order.schedule &&
        !path.includes('/pedidos/cadastro') &&
        order.status !== 'AGUARDANDO PAGAMENTO'
      ) {
        setNewOrders((old) => [...old.filter((o) => o.id !== order.id), order]);
        setScheduled(false);
        setOpenModalNew(true);
      }
    });

    appSocket.on('order_updated', async (order: OrderDTO) => {
      if (
        order &&
        !order.schedule &&
        !path.includes('/pedidos/cadastro') &&
        order.status === 'ACEITO' &&
        order.origin === 'TOTEM'
      ) {
        setNewOrders((old) => [...old.filter((o) => o.id !== order.id), order]);
        setScheduled(false);
        setOpenModalNew(true);
      }
    });

    appSocket.on('scheduled_orders', async (order: OrderDTO) => {
      setScheduledOrders((old) => [
        ...old.filter((o) => o.id !== order.id),
        order,
      ]);
      setScheduled(true);
      setOpenModalNew(true);
    });

    appSocket.emit('subscribe_to_messages', {});

    return () => {
      appSocket.disconnect();
      appSocket.removeAllListeners();

      console.log('socket desconnected');
    };
  }, []);

  useEffect(() => {
    if (newOrders.length > 0 || scheduledOrders.length > 0) {
      AlertSound.play();
    }
  }, [newOrders, scheduledOrders]);

  useEffect(() => {
    appSocket.off('new_branch_message');
    setPath(LocalPath.pathname);

    appSocket.on('new_branch_message', async (msg: MessageBranchProps) => {
      if (
        !LocalPath.pathname.includes(
          `/pedidos/${msg.chat_room?.order.id}/chat/cliente_loja`,
        ) &&
        msg.user?.user_role.name.toLowerCase() === 'cliente'
      ) {
        toast.info(
          <div
            style={{
              marginLeft: '20px',
            }}
          >
            <h4>
              {`PEDIDO #${
                (msg?.chat_room && msg?.chat_room?.order?.code) || '????'
              }`}
            </h4>
            <p
              style={{
                marginTop: '10px',
                paddingLeft: '10px',
                maxHeight: '50px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {msg.text || '--'}
            </p>
          </div>,
          {
            position: 'bottom-right',
            rtl: true,
            pauseOnFocusLoss: false,
            onOpen: () => MessageAlertSound.play(),
            theme: 'colored',
            autoClose: 10000,
            onClick: () => {
              if (msg?.chat_room?.order)
                dispatch(setSelectedOrder(msg?.chat_room?.order, 'view'));
              history.push(
                `/pedidos/${msg.chat_room?.order.id}/chat/cliente_loja`,
              );
            },
            icon: msg?.user?.avatar ? (
              <div
                style={{
                  width: '40px',
                  minWidth: '40px',
                  height: '40px',
                  minHeight: '40px',
                  borderRadius: '20px',
                  backgroundColor: theme.colors.yellow,
                  border: `1px solid ${theme.colors.white}`,
                  overflow: 'hidden',
                }}
              >
                <img
                  src={msg?.user?.avatar}
                  alt=""
                  style={{
                    width: '40px',
                    height: '40px',
                    objectFit: 'contain',
                  }}
                />
              </div>
            ) : (
              <div>
                <h2
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: theme.colors.primary,
                    backgroundColor: theme.colors.yellow,
                    border: `1px solid ${theme.colors.white}`,
                  }}
                >
                  {msg.user?.name ? msg.user?.name[0] : '?'}
                </h2>
              </div>
            ),
          },
        );
      }
    });
  }, [LocalPath.pathname]);

  return (
    <Container>
      {openModalNew && !path.includes('/pedidos/cadastro') && (
        <AlertOrderModal
          isOpen={openModalNew}
          orders={newOrders}
          requestClose={() => {
            setNewOrders([]);
            setOpenModalNew(false);
          }}
        />
      )}
      {openModalNew && scheduled && !path.includes('/pedidos/cadastro') && (
        <AlertOrderModal
          isOpen={openModalNew}
          orders={scheduledOrders}
          scheduled={scheduled}
          requestClose={() => {
            setNewOrders([]);
            setOpenModalNew(false);
          }}
        />
      )}
      <Siderbar />

      <Content>{children}</Content>
    </Container>
  );
};

export default AdminLayout;
