import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';

import * as ContributorActions from './actions';
import { ContributorSatate, ContributorTypes } from './types';

const INITIAL_STATE: ContributorSatate = {
  selectedContributor: null,
  viewType: null,
  page: 1,
  limitPerPage: 10,
  count: 0,
};

type ContributorReducers<Action extends AnyAction> = Reducer<
  ContributorSatate,
  Action
>;

const setSelectedContributor: ContributorReducers<
  ReturnType<typeof ContributorActions.setSelectedContributor>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedContributor: payload.dataContributor,
  viewType: payload.viewType,
});

const changePage: ContributorReducers<
  ReturnType<typeof ContributorActions.changePage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  page: payload.page,
});

const changeLimitPerPage: ContributorReducers<
  ReturnType<typeof ContributorActions.changeLimitPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  limitPerPage: payload.limitPerPage,
});

const changeCount: ContributorReducers<
  ReturnType<typeof ContributorActions.changeCount>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  count: payload.count,
});

const reducer = createReducer<ContributorSatate>(INITIAL_STATE)
  .handleAction(ContributorTypes.SELECTED_CONTRIBUTOR, setSelectedContributor)
  .handleAction(ContributorTypes.CHANGE_PAGE, changePage)
  .handleAction(ContributorTypes.CHANGE_LIMIT_PER_PAGE, changeLimitPerPage)
  .handleAction(ContributorTypes.CHANGE_COUNT, changeCount);

export default reducer;
