import { Reducer, AnyAction } from 'redux';
import { createReducer } from 'typesafe-actions';

import * as CouponActions from './actions';
import { CouponTypes, CouponState } from './types';

const INITIAL_STATE: CouponState = {
  selectedCouponId: null,
  viewTypeCoupon: null,
  couponPage: 1,
  couponLimitPerPage: 10,
  countCoupon: 0,
  productPage: 1,
  productLimitPerPage: 10,
  countProduct: 0,
};

type CouponReducers<Action extends AnyAction> = Reducer<CouponState, Action>;

const setSelectedCouponId: CouponReducers<
  ReturnType<typeof CouponActions.setSelectedCouponId>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  selectedCouponId: payload.couponId,
});

const setViewTypeCoupon: CouponReducers<
  ReturnType<typeof CouponActions.setViewTypeCoupon>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  viewTypeCoupon: payload.viewTypeCoupon,
});

const changeCouponPage: CouponReducers<
  ReturnType<typeof CouponActions.changeCouponPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  couponPage: payload.couponPage,
});

const changeCouponLimitPerPage: CouponReducers<
  ReturnType<typeof CouponActions.changeCouponLimitPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  couponLimitPerPage: payload.couponLimitPerPage,
});

const changeCountCoupon: CouponReducers<
  ReturnType<typeof CouponActions.changeCountCoupon>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  countCoupon: payload.countCoupon,
});

const changeProductPage: CouponReducers<
  ReturnType<typeof CouponActions.changeProductPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  productPage: payload.productPage,
});

const changeProductLimitPerPage: CouponReducers<
  ReturnType<typeof CouponActions.changeProductLimitPerPage>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  productLimitPerPage: payload.productLimitPerPage,
});

const changeCountProduct: CouponReducers<
  ReturnType<typeof CouponActions.changeCountProduct>
> = (state = INITIAL_STATE, { payload }) => ({
  ...state,
  countProduct: payload.countProduct,
});

const reducer = createReducer<CouponState>(INITIAL_STATE)
  .handleAction(CouponTypes.SELECTED_COUPON_ID, setSelectedCouponId)
  .handleAction(CouponTypes.SET_VIEW_TYPE_COUPON, setViewTypeCoupon)
  .handleAction(CouponTypes.CHANGE_COUPON_PAGE, changeCouponPage)
  .handleAction(
    CouponTypes.CHANGE_COUPON_LIMIT_PER_PAGE,
    changeCouponLimitPerPage,
  )
  .handleAction(CouponTypes.CHANGE_COUNT_COUPON, changeCountCoupon)
  .handleAction(CouponTypes.CHANGE_PRODUCT_PAGE, changeProductPage)
  .handleAction(
    CouponTypes.CHANGE_PRODUCT_LIMIT_PER_PAGE,
    changeProductLimitPerPage,
  )
  .handleAction(CouponTypes.CHANGE_COUNT_PRODUCT, changeCountProduct);

export default reducer;
